import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
/**
 * Отменяем запросы к апи при смене роута или "разрушении" компонента.
 */
export class HttpCancelService {
  private readonly abortHttpRequest$ = new Subject<void>();

  constructor() { }

  /**
   * Вызыватся в app.component в подписке на router.events. Тем самым отменяя все запросы у которых есть
   * "takeUntil(this.httpCancelService.getHttpRequestObserver())"
   */
  cancelHttpRequest() {
    this.abortHttpRequest$.next();
  }

  /**
   * Используется в запросах как "takeUntil(this.httpCancelService.getHttpRequestObserver())"
   */
  getHttpRequestObserver(): Observable<void> {
    return this.abortHttpRequest$.asObservable();
  }
}
