import { SYSTEM_PARAMETER_NAME } from 'web-frontend-component-library/enums';
import { DownloadStatesService } from 'web-frontend-component-library/services';
import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map } from "rxjs/operators";
import { Title } from '@angular/platform-browser';
import { Subscription } from "rxjs";
import { SystemParamsService } from "@core/services/system-params.service";
import { TokenSyncService } from 'web-frontend-component-library/services/token-sync';
import { HttpCancelService } from '@shared/services/http-cancel.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public hideHeader: boolean = true;
  public downloads = this.downloadStatesService.observeDownloads();

  private appShortTitle: string = null;
  private appFullTitle: string = null;
  private pageTitle: string = null;

  private publicSystemParamsSubscription: Subscription = new Subscription();

  constructor(
    private router: Router,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private downloadStatesService: DownloadStatesService,
    private systemParamsService: SystemParamsService,
    private tokenSyncService: TokenSyncService,
    private httpCancelService: HttpCancelService
  ) {
    this.publicSystemParamsSubscription = this.systemParamsService.getPublicSystemParams()
      .subscribe((systemParams: any) => {
        this.appShortTitle = systemParams && systemParams[SYSTEM_PARAMETER_NAME.PRODUCT_SHORT_NAME]
          ? systemParams[SYSTEM_PARAMETER_NAME.PRODUCT_SHORT_NAME] : null;
        this.appFullTitle = systemParams && systemParams[SYSTEM_PARAMETER_NAME.PRODUCT_FULL_NAME]
          ? systemParams[SYSTEM_PARAMETER_NAME.PRODUCT_FULL_NAME] : null;
        this.setPageTitle();
      });
  }

  ngOnInit() {
    this.tokenSyncService.init();
    this.router
      .events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          let child = this.activatedRoute.firstChild;
          while (child.firstChild) {
            child = child.firstChild;
          }
          if (child.snapshot.data['title']) {
            return child.snapshot.data['title'];
          }
          return null;
        })
      ).subscribe((ttl: string) => {
        this.pageTitle = ttl;
        this.setPageTitle();
        this.hideHeader = this.needHideHeader();
        this.httpCancelService.cancelHttpRequest();
      });
  }

  ngOnDestroy() {
    this.tokenSyncService.stop();
    this.publicSystemParamsSubscription.unsubscribe();
  }

  private setPageTitle() {
    let title: string = null;
    if (this.pageTitle) {
      title = this.appShortTitle ? `${this.appShortTitle}. ${this.pageTitle}` : this.pageTitle;
    } else {
      title = this.appFullTitle ? this.appFullTitle : null;
    }
    this.titleService.setTitle(title);
  }

  private needHideHeader(): boolean {
    return this.router.url.endsWith('page-not-found')
      || this.router.url.endsWith('page-wrong')
      || this.router.url.indexOf('auth') > -1;
  }
}
