import { Injectable } from '@angular/core';
import { BaseCardWithAccordionItemsService } from 'web-frontend-component-library/services';
import { INetworkSectionOperationHistory } from '@core/interfaces/network';
import { TranslateService } from '@ngx-translate/core';
import { IAccordionItem } from 'web-frontend-component-library/interfaces';

@Injectable({
  providedIn: 'root'
})
export class NetworkSectionCardService extends BaseCardWithAccordionItemsService<INetworkSectionOperationHistory> {
  private tabs: string[] = [];

  constructor(protected translateService: TranslateService) {
    super();
    this.translateService.get(['ENERGY_SYSTEMS.NETWORKS.SECTION.CARD.HISTORY.OPERATION_HISTORY.CARD'])
      .subscribe((result: string[]) => {
        this.tabs = result['ENERGY_SYSTEMS.NETWORKS.SECTION.CARD.HISTORY.OPERATION_HISTORY.CARD']['TABS'];
      });
  }

  /**
   * подготовка табов вкладки Сведения
   */
  prepareMainAccordionItems(): IAccordionItem[] {
    return [
      { id: 'info', title: this.tabs['INFO'], open: true },
      { id: 'in-object', title: this.tabs['IN_OBJECT'], open: true },
      { id: 'out-object', title: this.tabs['OUT_OBJECT'], open: true },
    ];
  }
}
