<div class="ProcessProgressIndicatorModalComponentClass">
  <div class="modal-body">
    <div class="process-progress-indicator-modal__info-box">
      <div *ngIf="titleTmpl"
           class="process-progress-indicator-modal__title">
        <ng-container *ngTemplateOutlet="
                        titleTmpl;
                        context: {
                          data: titleTmplData,
                          isSuccess: isSuccess,
                          uuids: uuids,
                          files: fileInfoList,
                          processResults: processResults
                        }
                      ">
        </ng-container>
      </div>
      <div *ngIf="title"
           class="process-progress-indicator-modal__title">
        <span class="title"
              *ngIf="!isSuccess"
              [innerHTML]="title"></span>
        <span class="title"
              *ngIf="isSuccess"
              translate="GENERAL.COMPLETED"></span>
      </div>
      <div class="process-progress-indicator-modal__message"
           *ngIf="!isSuccess">
        <span class="message"
              *ngIf="!processMessageTmpl"
              [innerHTML]="processMessage"></span>
        <ng-container *ngIf="processMessageTmpl">
          <ng-template *ngTemplateOutlet="
                        processMessageTmpl;
                        context: { 
                          data: processMessageTmplData, 
                          uuids: uuids 
                        }
                      ">
          </ng-template>
        </ng-container>
      </div>
      <div class="process-progress-indicator-modal__message"
           *ngIf="isSuccess">
        <span class="message"
              *ngIf="!successMessageTmpl"
              [innerHTML]="successMessage"></span>
        <ng-container *ngIf="successMessageTmpl">
          <ng-template *ngTemplateOutlet="
                        successMessageTmpl;
                        context: { 
                          data: successMessageTmplData, 
                          uuids: uuids, 
                          files: fileInfoList,
                          processResults: processResults
                        }
                      ">
          </ng-template>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <lib-button class="modal-footer-btn"
                [title]="goToButtonTitle"
                (OnClick)="goTo()">
    </lib-button>
    <lib-button class="modal-footer-btn"
                [title]="closeButtonTitle"
                (OnClick)="close()">
    </lib-button>
  </div>
</div>