import { LocalStorageService } from "web-frontend-component-library/services";
import { AuthService } from "../services/auth/auth.service";
import { SystemParamsService } from "../services/system-params.service";
import { TokenInterceptorConfig } from "web-frontend-component-library/services/token-interceptor";
import { HttpErrorResponse } from "@angular/common/http";
import { filter, map, switchMap, take } from "rxjs/operators";
import { SYSTEM_PARAMETER_NAME } from "web-frontend-component-library/enums";
import { Injector } from "@angular/core";

export function tokenInterceptorConfigFactory(
    injector: Injector
): TokenInterceptorConfig {
    return {
        getToken: () => injector.get(AuthService).getToken(),
        tokenable: req => injector.get(AuthService).isAuthorized() && !req.headers.get('Authorization') && !req.url.includes('/oauth/token'),
        fallback: error => {
            if (error instanceof HttpErrorResponse && error.status === 400) {
                document.location.href = injector.get(AuthService).getAuthCodeUrl(document.location.href);
            }
            else {
                let authUrl: string = injector.get(LocalStorageService).getObjectByName(SYSTEM_PARAMETER_NAME.AUTH_URL);
                const redirectUrl = document.location.href ? btoa(document.location.href) : null;
                if (authUrl) {
                    if (redirectUrl) {
                        authUrl += `?state=${redirectUrl}`;
                    }
                    document.location.href = authUrl;
                }
            }
        },
        refreshToken: () => injector.get(SystemParamsService).getPublicSystemParams()
            .pipe(
                filter(params => !!params),
                take(1),
                map(params => params[SYSTEM_PARAMETER_NAME.AUTH_URL]),
                switchMap(authUrl => injector.get(AuthService).refreshToken(authUrl))
            )

    };
}
