<div class="address-close">
    <lib-button [buttonStyles]="['icon']"
                icon="icon-calendar_clear"
                iconSize="21"
                (OnClick)="closeModalClick()">
    </lib-button>
</div>
<lib-loader [show]="isLoading"></lib-loader>
<div class="AddressCardClass">
    <div class="address-modal-header">
        <div class="universal-card-title">
            <span>{{titleCard}}</span>
        </div>
        <lib-button *ngIf="regime == 'add' && type=='object'"
                    class="modal-header-button address-import-button black"
                    [title]="'ADDRESS_CARD.IMPORT_ADDRESS' | translate"
                    (OnClick)="importAddress()">
        </lib-button>
        <lib-button *ngIf="canDelete"
                    class="address-delete"
                    [buttonStyles]="['icon']"
                    icon="icon-delete"
                    iconSize="20"
                    (OnClick)="confirmDelete()">
        </lib-button>
    </div>
    <div class="address-modal-body">
        <app-address-tree [object]="object"
                          [type]="type"
                          [regime]="regime"
                          [tree]="elementTree"
                          [treeSelect$]="treeSelect$"
                          (onElementSelect)="loadElement($event)"
                          (onObjectSelect)="loadObject($event)"
                          (onElementRemove)="updateSaveCounts()"
                          (onObjectRemove)="removeObject()">
        </app-address-tree>
        <app-address-object-card *ngIf="selectedType == 'object' && selectedObject"
                                 [object]="selectedObject"
                                 [tree]="elementTree"
                                 [regime]="regime"
                                 (onObjectChange)="onObjectChange($event)">
        </app-address-object-card>
        <app-address-element-card *ngIf="selectedType == 'element' && selectedElement"
                                  [element]="selectedElement"
                                  [tree]="elementTree"
                                  [regime]="regime"
                                  (OnElementChange)="onElementChange($event)">
        </app-address-element-card>
    </div>

    <div class="modal-footer">
        <div class="address-save-message"
             *ngIf="canSave()">
            <span>{{primarySaveSign}}</span>
            <span *ngIf="canSaveElementsCount > 0"> {{'ADDRESS_CARD.WILL_ADD_ELEMENTS' |
                translate:{'value':canSaveElementsCount} }}</span>
            <span *ngIf="canSaveElementsCount > 0 && (canSaveElementsTypesCount > 0 || canSaveObject)">, </span>
            <span *ngIf="canSaveElementsTypesCount > 0"> {{'ADDRESS_CARD.WILL_ADD_ELEMENT_TYPES' |
                translate:{'value':canSaveElementsTypesCount} }}</span>
            <span *ngIf="canSaveElementsTypesCount > 0 && canSaveObject">, </span>
            <span *ngIf="canSaveObject"> {{'ADDRESS_CARD.WILL_ADD_OBJECT' | translate }}</span>
        </div>
        <lib-button *ngIf="regime != 'view'"
                    class="modal-footer-btn filled"
                    [title]="saveButtonTitle"
                    [disabled]="!canSave()"
                    (OnClick)="saveData()">
        </lib-button>
    </div>
</div>