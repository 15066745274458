import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GroupTypeSettingsComponent } from './group-type-settings.component';
import { CheckboxListModule } from 'web-frontend-component-library/components/checkbox-list';
import { LockEditableModule } from 'web-frontend-component-library/components/lock-editable';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, LockEditableModule, CheckboxListModule, TranslateModule],
  declarations: [GroupTypeSettingsComponent],
  exports: [GroupTypeSettingsComponent],
})
export class GroupTypeSettingsModule {}
