import { SystemParamsService } from '@core/services/system-params.service';
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot } from "@angular/router";
import { Location } from "@angular/common";
import { AuthService } from "@core/services/auth/auth.service";
import { IUserInfo } from "@core/interfaces/user";
import { ToastrService } from "ngx-toastr";
import { VisibilityService, LocalStorageService, MenuService } from "web-frontend-component-library/services";
import { SYSTEM_PARAMETER_NAME } from 'web-frontend-component-library/enums';
import { TranslateService } from "@ngx-translate/core";
import { UserService } from "@core/services/user.service";
import { UserApiService } from "@core/services/api/user-api.service";
import { STORAGE_KEYS } from "@app/app.enums";
import { HttpErrorResponse } from "@angular/common/http";
import { AccessService } from '../services/access.service';
import {forkJoin} from "rxjs";
import {IAccessObjects, IAccessTree} from "web-frontend-component-library/interfaces";

@Injectable()
export class AuthorizationGuard implements CanActivateChild {

  private errors: string[];

  constructor(
    private router: Router,
    private location: Location,
    private authService: AuthService,
    private localStorage: LocalStorageService,
    private toastr: ToastrService,
    private visibilityService: VisibilityService,
    private translateService: TranslateService,
    private userService: UserService,
    private userApiService: UserApiService,
    private menuService: MenuService,
    private systemParamsService: SystemParamsService,
    private accessService: AccessService
  ) {
    this.translateService.get('LOGIN.ERRORS').subscribe((result: string[]) => {
      this.errors = result;
    });
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(childRoute, state);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const code: string = route.queryParamMap.get('code');
    const stateFromRedirect: string = route.queryParamMap.get('state');
    const stateString: string = this.localStorage.getObjectByName(STORAGE_KEYS.AUTH_STATE);
    if (code && stateFromRedirect && stateString && stateString === stateFromRedirect) {
      this.authService.getTokenFromCode(code).subscribe(
        (res) => {
          this.systemParamsService.updateSystemParams();
          const userInfo$ = this.userApiService.getUserInfo();
          const userAccessObjects$ = this.userApiService.getUserAccessObjects();
          const userAccessTree$ = this.userApiService.getUserAccessTree();
          forkJoin([userInfo$, userAccessObjects$, userAccessTree$]).subscribe(
            ([userInfo, accessObjects, accessTree]: [IUserInfo, IAccessObjects, IAccessTree]) => {
              this.accessService.getUserAccessTree(accessTree);
              this.accessService.getUserAccessObjects(accessObjects);
              this.accessService.updateUserAccessRights(userInfo.id);
              this.userService.userInfo = userInfo;
              this.navigateToRedirect();
              return false;
            }, (error) => {
              this.accessService.getUserAccessTree(null);
              this.accessService.getUserAccessObjects(null);
              this.toastr.error(this.errors['ERROR_USER']);
              this.navigateToRedirect();
              return false;
            });
        }, (error) => {
          if (error instanceof HttpErrorResponse && error.status === 400) {
            this.navigateToNoAccess(state.url);
          } else {
            this.navigateToLogin();
          }
          return false;
        });
    } else {
      this.navigateToLogin();
      return false;
    }
  }

  private navigateToNoAccess(url: string): void {
    this.router.navigate(['/no-access']).then(() => {
      this.location.replaceState(url);
    });
  }

  private navigateToLogin(): void {
    const authUrl: string = this.localStorage.getObjectByName(SYSTEM_PARAMETER_NAME.AUTH_URL);
    if (authUrl) {
      document.location.href = `${authUrl}/auth`;
    }
  }

  private navigateToRedirect() {
    const redirectTo = this.localStorage.getObjectByName(STORAGE_KEYS.REDIRECT_TO);
    this.localStorage.setObjectByName(STORAGE_KEYS.REDIRECT_TO, null);
    this.router.navigateByUrl(redirectTo ? redirectTo : '/main');
  }
}
