<div class="addGuideModalClass">
  <div class="modal-header">{{'GUIDE.LOAD_GUIDE' | translate}}</div>
  <div class="modal-body">
    <lib-loader [show]="isLoading"></lib-loader>
    <div class="add-guide-file">
      <div class="add-guide-file-content">
        <div class="add-guide-file-item">
          <div class="add-guide-file-item-label">
            <span class="form-field-label required">
              {{'GUIDE.FILE' | translate}}
            </span>
            <span class="font-icons icon-info"
                  [ngbTooltip]="'GUIDE.FILE_SIZE' | translate: {value: maxFileSize}"
                  [tooltipClass]="'card-field-tooltip'"
                  placement="bottom"
                  container="body"></span>
          </div>
          <div class="add-guide-file-field">
            <lib-files-list [id]="'add-guide-files'"
                            [multiple]="false"
                            [maxFileSize]="maxFileSize"
                            [types]="['pdf','doc','docx']"
                            [files]="file"
                            (OnChange)="file = $event">
            </lib-files-list>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <lib-button class="modal-footer-btn"
                (OnClick)="cancel()"
                [title]="'GENERAL.CANCEL' | translate"></lib-button>
    <lib-button class="modal-footer-btn filled"
                (OnClick)="apply()"
                [disabled]="!file"
                [title]="'GENERAL.LOAD' | translate"></lib-button>
  </div>
</div>