import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { BaseAuthService } from 'web-frontend-component-library/services/auth';
import { LocalStorageService } from 'web-frontend-component-library/services';
import { ITokenResponse } from 'web-frontend-component-library/interfaces';

@Injectable()
export abstract class AuthService
  extends BaseAuthService {

  constructor(
    protected localStorageService: LocalStorageService
  ) {
    super(localStorageService);
  }

  /**
   * Выход из системы
   */
  abstract logout(): Observable<any>;
  /**
   * Обновление токенов
   * @param authUrl урл ПАА
   */
  abstract refreshToken(authUrl: string): Observable<ITokenResponse>;
  /**
     * Получить УРЛ аутентификации в ПАА
     * @param currentUrl Текущий урл
     */
  abstract getAuthCodeUrl(currentUrl: string): string;
  /**
   * Получить токены на основе кода из ПАА
   * @param code Код
   */
  abstract getTokenFromCode(code: string): Observable<ITokenResponse>;
}
