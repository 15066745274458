import { Injectable } from '@angular/core';
import { AuthService } from "@core/services/auth/auth.service";
import { SYSTEM_PARAMETER_NAME } from "web-frontend-component-library/enums";
import { LocalStorageService } from 'web-frontend-component-library/services';
import { UserApiService } from "@core/services/api/user-api.service";
import { IUserInfo } from "@core/interfaces/user";
import { STORAGE_KEYS } from "@app/app.enums";
import { BehaviorSubject, Observable } from "rxjs";
import { AccessService } from './access.service';
import { NsiUserGroupAccessDto } from '@generatedDto/models';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private userInfoKey: string = STORAGE_KEYS.USER_INFO;
  private _userInfo: BehaviorSubject<IUserInfo> = new BehaviorSubject<IUserInfo>(null);

  constructor(
    private localStorageService: LocalStorageService,
    private authService: AuthService,
    private userApiService: UserApiService,
    private accessService: AccessService
  ) {
    if (this.authService.isAuthorized()) {
      this.userApiService
        .getUserInfo()
        .subscribe((userInfo: IUserInfo) => {
          this.userInfo = userInfo;
          this.accessService.updateUserAccessRights(userInfo?.id);
        });
    }
  }

  get userInfo(): IUserInfo {
    return this._userInfo.getValue();
  }

  set userInfo(userInfo: IUserInfo) {
    this._userInfo.next(userInfo);
    this.localStorageService.setObjectByName(this.userInfoKey, userInfo);
  }

  get userInfo$(): Observable<IUserInfo> {
    return this._userInfo.asObservable();
  }

  public getUserInfo(): Observable<IUserInfo> {
    return this._userInfo.asObservable();
  }

  public logout() {
    this.clearUserSession();
    const authUrl: string = this.localStorageService.getObjectByName(SYSTEM_PARAMETER_NAME.AUTH_URL);
    this.authService.logout().subscribe(
      responce => {
        if (authUrl) {
          this.localStorageService.removeAll();
          document.location.href = `${authUrl}/exit`;
        }
      },
      error => {
        if (authUrl) {
          this.localStorageService.removeAll();
          document.location.href = `${authUrl}/auth`;
        }
      }
    );
  }

  public clearUserSession() {
    this.userInfo = null;
    this.localStorageService.removeObjectByName(STORAGE_KEYS.AUTH);
    this.accessService.clearUserSession();
  }
}
