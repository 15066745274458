<div class="ImportAddressClass">
    <div class="modal-header">
        <div class="universal-card-title">
            <span>{{'ADDRESS_CARD.IMPORT_ADDRESS' | translate}}</span>
        </div>
    </div>
    <div class="import-address-body">
        <lib-search-field [debounce]="1000"
                          (OnSearch)="searchObjects($event)"></lib-search-field>
        <div class="import-address-list scroll-block">
            <lib-loader [show]="searchLoading"></lib-loader>
            <button *ngFor="let item of searchResults"
                    class="import-address-item"
                    [class.selected]="item == selectedItem"
                    (click)="selectedItem = item">
                <span class="content">{{item.name}}</span>
            </button>
        </div>
    </div>
    <div class="modal-footer">
        <lib-button class="modal-footer-btn"
                    [title]="'GENERAL.CANCEL' | translate"
                    (OnClick)="closeModal()">
        </lib-button>
        <lib-button class="modal-footer-btn filled"
                    [title]="'GENERAL.APPLY' | translate"
                    [disabled]="!selectedItem"
                    (OnClick)="applySelectedObject()">
        </lib-button>
    </div>

</div>