<div class="groupTypeSettingsClass">
  <div class="group-type-settings-item">
    <div class="group-type-settings-item-title">
      <span>{{'ADMIN.GROUPS.CARD.ACCESS_SETTINGS.RESOURCE_TYPE' | translate}}</span>
      <lib-lock-editable *ngIf="readonly"
                         class="group-type-settings-item-title-block"
                         [tooltipText]="'GENERAL.ACCESS_EDITABLE_TOOLTIP' | translate">
      </lib-lock-editable>
    </div>
    <lib-checkbox-list class="group-type-settings-item-list"
                       [selected]="resTypeAccessList"
                       checkListName="resourceTypes"
                       [checkboxList]="resTypes"
                       [disabled]="readonly"
                       [withSelfIconColor]="true"
                       (OnChange)="changeTypeResource($event)">
    </lib-checkbox-list>
  </div>
  <div class="group-type-settings-item">
    <div class="group-type-settings-item-title">
      <span>{{'ADMIN.GROUPS.CARD.ACCESS_SETTINGS.ACCOUNT_TYPE' | translate}}</span>
      <lib-lock-editable *ngIf="readonly || resTypeAccessList.length === 0"
                         class="group-type-settings-item-title-block"
                         [tooltipText]="'ADMIN.GROUPS.CARD.ACCESS_SETTINGS.TOOLTIP' | translate">
      </lib-lock-editable>
    </div>
    <lib-checkbox-list class="group-type-settings-item-list"
                       [selected]="accTypeAccessList"
                       checkListName="accountTypes"
                       [checkboxList]="accountTypes"
                       [disabled]="readonly || resTypeAccessList.length === 0"
                       (OnChange)="changeTypeAccount($event)">
    </lib-checkbox-list>
  </div>
  <div class="group-type-settings-item">
    <div class="group-type-settings-item-title">
      <span>{{'ADMIN.GROUPS.CARD.ACCESS_SETTINGS.MEASURE_POINT_TYPE' | translate}}</span>
      <lib-lock-editable *ngIf="readonly || resTypeAccessList.length === 0"
                         class="group-type-settings-item-title-block"
                         [tooltipText]="'ADMIN.GROUPS.CARD.ACCESS_SETTINGS.TOOLTIP' | translate">
      </lib-lock-editable>
    </div>
    <lib-checkbox-list class="group-type-settings-item-list"
                       [selected]="mpTypeAccessList"
                       checkListName="measurePointTypes"
                       [checkboxList]="measurePointTypes"
                       [disabled]="readonly || resTypeAccessList.length === 0"
                       (OnChange)="changeTypeMeasurePoint($event)">
    </lib-checkbox-list>
  </div>
</div>
