<div class="AddressObjectCardClass">
    <div class="address-object-header">
        <ng-container *ngFor="let item of headers">
            {{item}}
            <span class="font-icons icon-forward-long"></span>
        </ng-container>
    </div>

    <div class="address-object-content scroll-block">
        <lib-tabs *ngIf="regime=='add'"
                  [tabs]="tabsItems"
                  [selected]="activeTabKey"
                  (OnSelect)="changeTab($event)">
        </lib-tabs>

        <app-address-search *ngIf="activeTabKey=='FIAS' && !object.richData"
                            [displayItems]="searchResults"
                            (OnSearch)="searchObjects($event)"
                            (OnSelectValue)="selectObject($event)">
        </app-address-search>

        <ng-container *ngTemplateOutlet="activeTabKey=='MANUAL' || object.richData ? formTemplate : null"></ng-container>
        <ng-container *ngTemplateOutlet="object.richData ? viewTemplate : null"></ng-container>
    </div>
</div>

<ng-template #formTemplate>
    <form class="address-object-form" [formGroup]="form">
        <div class="address-object-item">
            <div class="address-object-item-label" [class.required]="activeTabKey=='MANUAL'">
                <span>{{'ADDRESS_CARD.OBJECT_FIELDS.TYPE' | translate}}</span>
            </div>
            <div class="address-object-item-field dictionary">
                <lib-simple-dictionary
                  class="address-element-card-form-item-select"
                  [titleDictionary]="'ADDRESS_CARD.OBJECT_FIELDS.TYPE' | translate"
                  [withRemove]="true"
                  [title]="'GENERAL.SELECT' | translate"
                  [loadingInProgress]="estateTypeDictionary.dictLoading"
                  [params]="estateTypeDictionary.dictParams"
                  [list]="estateTypeDictionary.dictList"
                  [selected]="form.controls['addressEstateType'].value"
                  [disabled]="regime == 'view' || activeTabKey == 'FIAS'"
                  [disabledTooltip]="'ADDRESS_CARD.TOOLTIPS.ADDRESS_TOOLTIP' | translate"
                  (OnLoadList)="updateDictionaryList($event, estateTypeDictionary)"
                  (OnSelect)="dictionarySelectItem($event, 'addressEstateType')">
                </lib-simple-dictionary>
            </div>
        </div>

        <div class="address-object-item two-field">
            <div class="address-object-item-label">
                <span>{{'ADDRESS_CARD.OBJECT_FIELDS.ADDRESS' | translate}}</span>
            </div>
            <div class="address-object-item-field address">
                <span>{{addressField}}</span>
            </div>
        </div>


        <div class="address-object-item one-field">
            <div class="address-object-item-label">
                <span>{{'ADDRESS_CARD.OBJECT_FIELDS.EXT_ADDRESS' | translate}}</span>
            </div>
            <div class="address-object-item-field">
                <lib-input formControlName="addInfo"
                           class="address-object-item-input long"
                           [id]="'addInfo'"
                           [maxLength]="4000"
                           [disablePlaceholderAnimation]="true"
                           [disabledTooltip]="'ADDRESS_CARD.TOOLTIPS.ADDRESS_TOOLTIP' | translate">
                </lib-input>
            </div>
        </div>

        <div class="address-object-item">
            <div class="address-object-item-label"
                [class.required]="activeTabKey=='MANUAL' && form.value.addressEstateType">
                <span>{{'ADDRESS_CARD.OBJECT_FIELDS.NUMBER_AREA' | translate}}</span>
            </div>
            <div class="address-object-item-field">
                <lib-input formControlName="objectNumber"
                           class="address-object-item-input"
                           [id]="'objectNumber'"
                           [maxLength]="50"
                           [disablePlaceholderAnimation]="true"
                           [disabledTooltip]="'ADDRESS_CARD.TOOLTIPS.NUMBER_AREA_TOOLTIP' | translate"
                           [required]="form.value.addressEstateType">
                </lib-input>
            </div>
        </div>
        <div class="address-object-item">
            <div class="address-object-item-label">
                <span>{{'ADDRESS_CARD.OBJECT_FIELDS.NUMBER_HOUSE' | translate}}</span>
            </div>
            <div class="address-object-item-field">
                <lib-input formControlName="housingNumber"
                           class="address-object-item-input"
                           [id]="'housingNumber'"
                           [maxLength]="50"
                           [disablePlaceholderAnimation]="true"
                           [disabledTooltip]="'ADDRESS_CARD.TOOLTIPS.ADDRESS_TOOLTIP' | translate">
                </lib-input>
            </div>
        </div>
        <div class="address-object-item">
            <div class="address-object-item-label"
                [class.required]="activeTabKey=='MANUAL' && form.value.addressBuildingType">
                <span>{{'ADDRESS_CARD.OBJECT_FIELDS.NUMBER_BUILD' | translate}}</span>
            </div>
            <div class="address-object-item-field">
                <lib-input formControlName="buildingNumber"
                           class="address-object-item-input"
                           [id]="'buildingNumber'"
                           [maxLength]="50"
                           [disablePlaceholderAnimation]="true"
                           [disabledTooltip]="'ADDRESS_CARD.TOOLTIPS.NUMBER_BUILD_TOOLTIP' | translate"
                           [required]="form.value.addressBuildingType">
                </lib-input>
            </div>
        </div>
        <div class="address-object-item">
            <div class="address-object-item-label">
                <span>{{'ADDRESS_CARD.OBJECT_FIELDS.BUILD_TYPE' | translate}}</span>
            </div>
            <div class="address-object-item-field dictionary">
                <lib-simple-dictionary
                  class="address-element-card-form-item-select"
                  [titleDictionary]="'ADDRESS_CARD.OBJECT_FIELDS.BUILD_TYPE' | translate"
                  [withRemove]="true"
                  [title]="'GENERAL.SELECT' | translate"
                  [loadingInProgress]="buildingTypeDictionary.dictLoading"
                  [params]="buildingTypeDictionary.dictParams"
                  [list]="buildingTypeDictionary.dictList"
                  [selected]="form.controls['addressBuildingType'].value"
                  [disabled]="regime == 'view' || activeTabKey == 'FIAS'"
                  [disabledTooltip]="'ADDRESS_CARD.TOOLTIPS.ADDRESS_TOOLTIP' | translate"
                  (OnLoadList)="updateDictionaryList($event, buildingTypeDictionary)"
                  (OnSelect)="dictionarySelectItem($event, 'addressBuildingType')">
                </lib-simple-dictionary>
            </div>
        </div>
        <div class="address-object-item">
            <div class="address-object-item-label">
                <span>{{'ADDRESS_CARD.OBJECT_FIELDS.POSTCODE' | translate}}</span>
            </div>
            <div class="address-object-item-field">
                <lib-input formControlName="postalCode"
                           class="address-object-item-input"
                           [id]="'postalCode'"
                           [disablePlaceholderAnimation]="true"
                           [disabledTooltip]="'ADDRESS_CARD.TOOLTIPS.ADDRESS_TOOLTIP' | translate"
                           [maxLength]="6">
                </lib-input>
            </div>
        </div>
        <div class="address-object-item">
            <div class="address-object-item-label" [class.required]="activeTabKey=='MANUAL'">
                <span>{{'ADDRESS_CARD.OBJECT_FIELDS.START_DATE' | translate}}</span>
            </div>
            <div class="address-object-item-field">
                <lib-calendar formControlName="startDate"
                              [format]="dateFormat"
                              [disabledTooltip]="'ADDRESS_CARD.TOOLTIPS.ADDRESS_TOOLTIP' | translate"
                              [id]="'startDate'">
                </lib-calendar>
            </div>
        </div>
        <div class="address-object-item">
            <div class="address-object-item-label">
                <span>{{'ADDRESS_CARD.OBJECT_FIELDS.END_DATE' | translate}}</span>
            </div>
            <div class="address-object-item-field">
                <lib-calendar formControlName="endDate"
                              [format]="dateFormat"
                              [disabledTooltip]="'ADDRESS_CARD.TOOLTIPS.ADDRESS_TOOLTIP' | translate"
                              [id]="'endDate'">
                </lib-calendar>
            </div>
        </div>
        <div class="address-object-item">
            <div class="address-object-item-label">
                <span>{{'ADDRESS_CARD.OBJECT_FIELDS.BUILD_CONDITION' | translate}}</span>
            </div>
            <div class="address-object-item-field dictionary">
                <lib-simple-dictionary
                  class="address-element-card-form-item-select"
                  [titleDictionary]="'ADDRESS_CARD.OBJECT_FIELDS.BUILD_CONDITION' | translate"
                  [withRemove]="true"
                  [title]="'GENERAL.SELECT' | translate"
                  [loadingInProgress]="buildingStateDictionary.dictLoading"
                  [params]="buildingStateDictionary.dictParams"
                  [list]="buildingStateDictionary.dictList"
                  [selected]="form.controls['addressBuildingState'].value"
                  [disabled]="regime == 'view' || activeTabKey == 'FIAS'"
                  [disabledTooltip]="'ADDRESS_CARD.TOOLTIPS.ADDRESS_TOOLTIP' | translate"
                  (OnLoadList)="updateDictionaryList($event, buildingStateDictionary)"
                  (OnSelect)="dictionarySelectItem($event, 'addressBuildingState')">
                </lib-simple-dictionary>
            </div>
        </div>
        <div class="address-object-item">
            <div class="address-object-item-label">
                <span>{{'ADDRESS_CARD.OBJECT_FIELDS.CADASTRAL_NUMBER' | translate}}</span>
            </div>
            <div class="address-object-item-field">
                <lib-input formControlName="kadastrNumber"
                           class="address-object-item-input"
                           [id]="'kadastrNumber'"
                           [disablePlaceholderAnimation]="true"
                           [disabledTooltip]="'ADDRESS_CARD.TOOLTIPS.ADDRESS_TOOLTIP' | translate">
                </lib-input>
            </div>
        </div>
    </form>
</ng-template>

<ng-template #viewTemplate>
    <div class="address-object-view">
        <div *ngIf="object.richData.regionCode" class="address-object-item">
          <div class="address-object-item-label">
            <span>{{'ADDRESS_CARD.OBJECT_FIELDS.REGION_CODE' | translate}}</span>
          </div>
          <div class="address-object-item-value">
           <span>{{object.richData.regionCode}}</span>
          </div>
        </div>
        <div class="address-object-item">
            <div class="address-object-item-label">
                <span>{{'ADDRESS_CARD.OBJECT_FIELDS.ID' | translate}}</span>
            </div>
            <div class="address-object-item-value">
                <span>{{object.richData.id}}</span>
            </div>
        </div>
        <div class="address-object-item two-field">
            <div class="address-object-item-label">
                <span>{{'ADDRESS_CARD.OBJECT_FIELDS.UID' | translate}}</span>
            </div>
            <div class="address-object-item-value">
                <span>{{object.richData.systemGuid ? object.richData.systemGuid : object.richData.globalId}}</span>
            </div>
        </div>
        <div class="address-object-item">
            <div class="address-object-item-label">
                <span>{{'ADDRESS_CARD.OBJECT_FIELDS.SOURCE' | translate}}</span>
            </div>
            <div class="address-object-item-value">
                <span>{{object.richData.source}}</span>
            </div>
        </div>
        <div class="address-object-item">
            <div class="address-object-item-label">
                <span>{{'ADDRESS_CARD.OBJECT_FIELDS.ADD_DATE' | translate}}</span>
            </div>
            <div class="address-object-item-value">
                <span>{{object.richData.created}}</span>
            </div>
        </div>
        <div class="address-object-item">
            <div class="address-object-item-label">
                <span>{{'ADDRESS_CARD.OBJECT_FIELDS.UPDATE_DATE' | translate}}</span>
            </div>
            <div class="address-object-item-value">
                <span>{{object.richData.modified}}</span>
            </div>
        </div>
    </div>
</ng-template>
