import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { UserService } from "@core/services/user.service";
import { SystemParamsService } from "@core/services/system-params.service";
import { USER_ACCESS } from "@app/app.enums";
import { Subscription } from "rxjs";
import { AddGuideModalComponent } from "@shared/components/add-guide-modal/add-guide-modal.component";
import { GuideApiService } from "@core/services/api/admin/guide-api.service";
import {
  BreadcrumbsService,
  MenuService,
  OpenModalService
} from 'web-frontend-component-library/services';
import { IUserInfo } from '@core/interfaces/user';
import { SYSTEM_PARAMETER_NAME } from 'web-frontend-component-library/enums';
import { IUserMenu } from 'web-frontend-component-library/interfaces';
import { ConfirmModalComponent } from 'web-frontend-component-library/components/confirm-modal';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit, OnDestroy {

  public user: IUserInfo;
  public supportPhone: string;
  public supportEmail: string;
  public mainUrl: string = '/main';
  public homeUrl: string = '';
  public isAdmin: boolean = false;
  // public hasUnreadNotifications: boolean = true;
  public showSupportBalloon: boolean = false;
  public showUserBalloon: boolean = false;
  public showGuideBalloon: boolean = false;
  private exitTitles: string[];
  private publicSystemParamsSubscription: Subscription;
  private menuSubscription: Subscription;
  private userInfoSubsctiption: Subscription;

  public breadcrumbs$ = this.breadcrumbsService.observeBreadcrumbs({
    renderNeighbours: 'last',
    accessObjectsKey: 'userAccessObjects'
  });

  public guidePopupContainerAutofocusEvent: Event;

  constructor(
    private menuService: MenuService,
    private modalService: OpenModalService,
    private translateService: TranslateService,
    private userService: UserService,
    private systemParamsService: SystemParamsService,
    private guideApiService: GuideApiService,
    private breadcrumbsService: BreadcrumbsService
  ) {
  }

  ngOnInit() {
    this.translateService.get(['GENERAL.LOGOUT_QUESTION', 'GENERAL.EXIT']).subscribe((result: string[]) => {
      this.exitTitles = result;
    });
    this.publicSystemParamsSubscription = this.systemParamsService.getPublicSystemParams()
      .subscribe((systemParams: any) => {
        this.supportPhone = systemParams ? systemParams[SYSTEM_PARAMETER_NAME.SUPPORT_PHONE] : '';
        this.supportEmail = systemParams ? systemParams[SYSTEM_PARAMETER_NAME.SUPPORT_EMAIL] : '';
        this.homeUrl = systemParams && systemParams[SYSTEM_PARAMETER_NAME.AUTH_URL]
          ? `${systemParams[SYSTEM_PARAMETER_NAME.AUTH_URL]}/home` : '';
      });
    this.menuSubscription = this.menuService.getUserMenu()
      .subscribe((menu: IUserMenu[]) => {
        this.isAdmin = menu && menu.length > 0
          ? !!menu.find((item: IUserMenu) => item.code === USER_ACCESS['ADMIN'])
          : false;
      });
    this.userInfoSubsctiption = this.userService.getUserInfo().subscribe(user => {
      this.user = user;
    });
  }

  ngOnDestroy() {
    this.publicSystemParamsSubscription.unsubscribe();
    this.menuSubscription.unsubscribe();
    this.userInfoSubsctiption.unsubscribe();
  }

  public toggleMenu(open: boolean) {
    this.menuService.setOpenedMenu(open);
  }

  public downloadGuide() {
    this.setGuideBallonOpened(false);
    this.guideApiService.getGuideFile();
  }

  public loadGuide() {
    this.setGuideBallonOpened(false);
    this.modalService.show({
      component: AddGuideModalComponent,
      options: {
        centered: true,
        windowClass: 'modal-measure-card'
      },
      callbacks: {
        Enter: 'apply',
        Escape: 'cancel'
      }
    });
  }

  public setSupportBalloonOpened(value: boolean) {
    this.showSupportBalloon = value;
  }

  public setUserBalloonOpened(value: boolean) {
    this.showUserBalloon = value;
  }

  public setGuideBallonOpened(value: boolean) {
    this.showGuideBalloon = value;
  }

  public exit() {
    this.setUserBalloonOpened(false);
    this.modalService.show({
      component: ConfirmModalComponent,
      data: {
        question: this.exitTitles['GENERAL.LOGOUT_QUESTION'],
        applyTitle: this.exitTitles['GENERAL.EXIT'],
        onApply: () => {
          this.userService.logout();
        }
      },
      options: {
        centered: true,
        windowClass: 'modal-confirm'
      }
    });
  }
}
