import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class ContentTypeInterceptor implements HttpInterceptor {
  private readonly targets: string[] = [
    '/admin/software/rental/version/file',
    '/photo/upload',
    '/warranty/upload',
    '/document/upload',
    '/document/other/upload',
    '/document/verification/upload',
    '/document/exploitation/upload',
    '/document/staging/upload',
    '/admin/user/manual/upload',
    '/device/import/upload',
    '/delivery/contract/file/upload',
    '/delivery/contract/detail/file/upload',
    '/system/file/upload',
    '/user/file/upload',
    '/regime-season/file',
    '/file/upload',
    '/psi/upload',
    '/journal/temperature/upload',
    '/manual-input/upload/',
    '/agreement/file',
    '/service/team/file/upload',
    '/device/checkup/plan/file/upload',
    '/device/checkup/plan/action/file/upload',
    '/device/checkup/plan/action/job/file/upload',
    '/journal/temperature/upload',
    '/emergency-work/request/file/upload',
    '/meteo/station/journal/temperature/mass/upload',
    '/device/preparation/plan/file/upload',
    '/device/preparation/plan/action/file/upload',
    '/device/preparation/plan/action/job/file/upload',
    '/emergency-situation/file/upload',
  ];
  private readonly ignore: string[] = ['/agreement/file/download/stream'];

  constructor() { }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.needDeleteContentType(req.url)) {
      req = this.deleteContentType(req);
    }
    return next.handle(req);
  }

  private needDeleteContentType(url: string): boolean {
    return this.targets.some((t) => url.includes(t)) && !this.ignore.some((t) => url.includes(t));
  }

  private deleteContentType(req: HttpRequest<any>): HttpRequest<any> {
    let headers = new HttpHeaders({
      Authorization: req.headers.get('Authorization'),
    });
    return req.clone({ headers });
  }
}
