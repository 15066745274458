import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "@env/environment";
import {Observable} from "rxjs";
import {map, share} from "rxjs/operators";
import {IUserInfo} from "@core/interfaces/user";
import {IAccessObjects, IAccessTree} from "web-frontend-component-library/interfaces";
import { NsiUserGroupAccessDto } from "@generatedDto/models";
import {MENU_ADDITIONAL} from "@app/app.enums";

@Injectable({
  providedIn: 'root'
})
export class UserApiService {

  constructor(
    private HttpClient: HttpClient
  ) {}

  public getUserAccessObjects(): Observable<IAccessObjects> {
    return this.HttpClient.get<IAccessObjects>(`${environment.API_URL}/user/access/object`).pipe(share());
  }

  public getUserAccessTree(): Observable<IAccessTree> {
    return this.HttpClient.get<IAccessTree>(`${environment.API_URL}/user/access/tree`)
      .pipe(map((access: IAccessTree) => {
        let result = access;
        if (access && access.uiObjects) {
          result.uiObjects = [...access.uiObjects, ...MENU_ADDITIONAL];
        }
        return result;
      }), share());
  }

  public getUserInfo(): Observable<IUserInfo> {
    return this.HttpClient.get<IUserInfo>(`${environment.API_URL}/user/info/short`).pipe(share());
  }
  /**
   * Информация о правах работы с типами ресурсов, типами учета и типами ТУ
   */
  public getUserAccessRights(userId: number): Observable<NsiUserGroupAccessDto> {
    return this.HttpClient.get<NsiUserGroupAccessDto>(`${environment.API_URL}/admin/user/${userId}/access`);
  }

  /**
   * Информация о правах работы с типами ресурсов, типами учета и типами ТУ
   */
  public getProfileAccessRights(): Observable<NsiUserGroupAccessDto> {
    return this.HttpClient.get<NsiUserGroupAccessDto>(`${environment.API_URL}/profile/user/access`);
  }
}
