import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { share } from 'rxjs/operators';
import {ISoftwareVersionParams, ISystemParams} from '@core/interfaces/system-params';

@Injectable({
  providedIn: 'root',
})
export class SystemParamsApiService {
  constructor(private HttpClient: HttpClient) {}

  public getSystemParams(params: string[]): Observable<ISystemParams> {
    return this.HttpClient.post<ISystemParams>(
      `${environment.API_URL}/configuration/system/parameter`,
      { parameters: params },
    ).pipe(share());
  }

  public getPublicSystemParams(): Observable<ISystemParams> {
    return this.HttpClient.get<ISystemParams>(
      `${environment.API_URL}/public/parameter`,
    ).pipe(share());
  }

  public getSoftwareVersionParams(): Observable<ISoftwareVersionParams> {
    return this.HttpClient.get<ISoftwareVersionParams>(
      `${environment.API_URL}/public/software/version`,
    ).pipe(share());
  }
}
