import { TokenSyncConfig } from "web-frontend-component-library/services/token-sync";
import { AuthService } from "../services/auth/auth.service";
import { STORAGE_KEYS } from "@app/app.enums";

export function tokenSyncConfigFactory(
    authService: AuthService
): TokenSyncConfig {
    return {
        tokenLocalStorageKey: STORAGE_KEYS.AUTH,
        tokenBroadcastChannelKey: STORAGE_KEYS.AUTH,
        getTokens: () => authService.tokenResponse,
        onChangeTokens: tokens => {
            authService.tokenResponse = tokens;
        },
        observeTokens: () => authService.observeTokenResponse()
    }
}