<div class="generate-progress-indicator__success">
  <span class="generate-progress-indicator__success-item">
    <span translate="ENERGY_RESOURCES.MEASURE_PPI_REPORT_GENERATE.REFORM_SUCCESS_MESSAGE"></span>
    <span *ngIf="files?.length > 0" translate="ENERGY_RESOURCES.MEASURE_PPI_REPORT_GENERATE.DOWNLOAD"></span>
  </span>
  <ul class="generate-progress-indicator__success-item" *ngIf="files?.length > 0">
    <li class="generate-progress-indicator__link" *ngFor="let file of files"
      (click)="download(file?.reportLogId, file?.fileName)">
      {{file?.fileName}}
    </li>
  </ul>
  <span class="generate-progress-indicator__success-item" *ngIf="files?.length < uuids?.length">
    <span>
      {{'ENERGY_RESOURCES.MEASURE_REPORT_GENERATE.REFORM_ERROR_MESSAGE' | translate: {completed: files?.length,
      total:uuids?.length} }}
    </span>
    <span class="generate-progress-indicator__success-item">
      <span translate="ENERGY_RESOURCES.MEASURE_PPI_REPORT_GENERATE.DETAILS_LINK"></span>
      <span [ngClass]="{
        'generate-progress-indicator__link': accesses.canGoToDownloadsJournal
      }" translate="ENERGY_RESOURCES.MEASURE_PPI_REPORT_GENERATE.GO_TO_JOURNAL_MESSAGE"
        (click)="goToDownloadsJournalHandler()">
      </span>
    </span>
  </span>
</div>
