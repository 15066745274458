import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberAbs',
})
export class NumberAbsPipe implements PipeTransform {
  transform(value: number | string): number {
    return value || +value === 0 ? Math.abs(+value) : null;
  }
}
