import { environment } from '@env/environment';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from "@core/guards/auth-guard.service";
import { NoAccessSoftwareRentContractComponent } from "@shared/components/no-access-software-rent-contract/no-access-software-rent-contract.component";
import { NgModule } from '@angular/core';
import { AuthorizationComponent } from "@shared/components/authorization/authorization.component";
import { AuthorizationGuard } from "@core/guards/authorization-guard.service";
import { QuicklinkStrategy } from 'ngx-quicklink';
import { NoAccessComponent } from 'web-frontend-component-library/components/no-access';
import { PageNotFoundComponent } from 'web-frontend-component-library/components/page-not-found';
import { PageWrongComponent } from 'web-frontend-component-library/components/page-wrong';

const routes: Routes = [
  { path: '', redirectTo: 'main', pathMatch: 'full' },
  {
    path: 'main',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/main/main.module').then(m => m.MainModule),
    data: {
      preload: false
    }
  },
  {
    path: 'auth',
    canActivate: [AuthorizationGuard],
    component: AuthorizationComponent
  },
  {
    path: 'admin',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: 'contracts',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/contracts/contracts.module').then(m => m.ContractsModule)
  },
  {
    path: 'nsi',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/admin-nsi/admin-nsi.module').then(m => m.AdminNsiModule)
  },
  {
    path: 'energy-resources',
    canActivate: [AuthGuard],
    loadChildren: () => import('@modules/energy-resources/energy-resources.module').then(m => m.EnergyResourcesModule)
  },
  {
    path: 'personal-area',
    canActivate: [AuthGuard],
    loadChildren: () => import('@modules/personal-area/personal-area.module').then(m => m.PersonalAreaModule)
  },
  {
    path: 'dispatcher',
    canActivate: [AuthGuard],
    loadChildren: () => import('@modules/dispatcher/dispatcher.module').then(m => m.DispatcherModule)
  },
  {
    path: 'maintainence',
    canActivate: [AuthGuard],
    loadChildren: () => import('@modules/toir/toir.module').then(m => m.ToirModule)
  },
  {
    path: 'energy-systems',
    canActivate: [AuthGuard],
    loadChildren: () => import('@modules/energy-systems/energy-systems.module').then(m => m.EnergySystemsModule)
  },
  {
    path: 'analytics',
    canActivate: [AuthGuard],
    loadChildren: () => import('@modules/analytics/analytics.module').then(m => m.AnalyticsModule)
  },
  ...environment.production ? [] : [{
    path: 'test',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/test/test.module').then(m => m.TestModule),
    data: {
      preload: false
    }
  }],
  { path: 'no-access-contract', component: NoAccessSoftwareRentContractComponent },
  { path: 'no-access', component: NoAccessComponent },
  { path: 'page-wrong', component: PageWrongComponent },
  { path: 'page-not-found', component: PageNotFoundComponent },
  { path: '**', redirectTo: 'page-not-found' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      { preloadingStrategy: QuicklinkStrategy }
    )
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
