import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NetworkSectionCardApi } from '@shared/components/network-section-card/network-section-card-api.interface';
import { ResourceTypeCode, MeasureObjectCategory } from 'web-frontend-component-library/enums';
import { BehaviorSubject, Subject } from 'rxjs';
import { INetworkSectionOperationHistory } from '@core/interfaces/network';
import { IAccordionItem } from 'web-frontend-component-library/interfaces';
import { DateFormat } from 'web-frontend-component-library/services';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NetworkSectionCardService } from '@shared/components/network-section-card/network-section-card.service';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-network-section-card',
  templateUrl: './network-section-card-shared.component.html',
  styleUrls: ['./network-section-card-shared.component.scss']
})
export class NetworkSectionCardSharedComponent implements OnInit, OnDestroy {
  @Input() service: NetworkSectionCardApi;

  @Input() networkId: number;
  @Input() sectionId: number;
  @Input() measureObjectId: number;
  @Input() networkHistoryId: number;

  info: INetworkSectionOperationHistory;
  accordionItems: IAccordionItem[] = [];
  networkResourceType: ResourceTypeCode;

  readonly ResourceType = ResourceTypeCode;
  readonly MeasureObjectCategory = MeasureObjectCategory;
  readonly dateFormat = DateFormat.DATE;
  readonly dateTimeFormat = DateFormat.DATE_TIME_WITH_SECONDS;

  readonly isLoading$ = new BehaviorSubject<boolean>(false);
  private readonly onDestroy$ = new Subject<any>();

  constructor(
    private router: Router,
    private activeModal: NgbActiveModal,
    private toastrService: ToastrService,
    private translateService: TranslateService,
    private networkSectionCardService: NetworkSectionCardService
  ) {
    this.networkSectionCardService.init();
    this.networkSectionCardService.setAccordionItems(
      this.networkSectionCardService.prepareMainAccordionItems()
    );
  }

  ngOnInit(): void {
    this.getCardInfo();
    this.networkSectionCardService
      .observeAccordionItems()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result: IAccordionItem[]) => {
        this.accordionItems = result;
      });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }


  /**
   * функция сворачивания/разворачивания вкладки
   * @param item
   */
  toggleAccordionItem(item: IAccordionItem) {
    this.networkSectionCardService.setAccordionItemOpen(item);
  }

  goToNetworkSection() {
    window.open(`/energy-systems/networks/${this.networkId}/section/${this.sectionId}`, '_blank');
  }

  goToMeasureObject(moId: number) {
    if (moId) {
      window.open(`/energy-resources/measure-objects/${moId}`, '_blank');
    }
  }

  close() {
    this.info = null;
    this.activeModal.close();
  }

  private getCardInfo() {
    const observer$ = this.measureObjectId
      ? this.service.getNetworkSectionCardInfo(this.networkHistoryId, this.measureObjectId)
      : this.service.getNetworkSectionCardInfo(this.networkHistoryId, this.networkId, this.sectionId);

    this.isLoading$.next(true);
    observer$.pipe(
      finalize(() => this.isLoading$.next(false))
    ).subscribe({
      next: (res) => {
        this.info = res ?? null;
        this.networkId = this.networkId || res.networkId;
        this.sectionId = this.sectionId || res.networkSectionId;
        this.networkResourceType = res.networkResourceType;
        this.networkSectionCardService.setState(res);
      },
      error: error => {
        if (error?.status && error?.status === 403) {
          this.toastrService.error('',
            this.translateService.instant('ENERGY_SYSTEMS.NETWORKS.SECTION.CARD.HISTORY.OPERATION_HISTORY.CARD.ERROR.ERROR_DELETED'));
          this.router.navigate(['/page-not-found']);
        } else {
          this.toastrService.error(
            error?.error?.message ? error.error.message : '',
            this.translateService.instant('GENERAL.ERROR_LOAD')
          );
        }
      }
    });
  }
}
