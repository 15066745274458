import { Injectable } from '@angular/core';
import { DictionaryApiService } from '@app/core/services/api/dictionary-api.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { IDictionaryField, ISelectItem } from 'web-frontend-component-library/interfaces';
import { UniversalGridUtilsService } from 'web-frontend-component-library/grid/services/universal-grid-utils';
import { EngineerDeviceType } from '@app/app.enums';
import { DateTimeService, DictionaryService } from 'web-frontend-component-library/services';
import { FilterType } from 'web-frontend-component-library/enums';

@Injectable({
  providedIn: 'root',
})
export class AsuerUniversalGridUtilsService extends UniversalGridUtilsService {
  private emptyList: ISelectItem[] = [];
  private activeList2: ISelectItem[] = [];

  constructor(
    private dictionaryApiService: DictionaryApiService,
    private dictionaryService: DictionaryService,
    protected dateTimeService: DateTimeService,
    protected translateService: TranslateService,
  ) {
    super(translateService, dateTimeService);
    this.translateService.get(['GENERAL']).subscribe((res) => {
      this.emptyList = [
        { key: 'false', value: res['GENERAL']['EMPTY'] },
        { key: 'true', value: res['GENERAL']['NO_EMPTY'] },
      ];
      this.activeList2 = [
        { key: 'true', value: res['GENERAL']['ACTIVE2'] },
        { key: 'false', value: res['GENERAL']['NO_ACTIVE2'] },
      ];
    });
  }

  public getDictionaryType(dictCode: string): FilterType.select | FilterType.simpleDictionary {
    let type: FilterType.select | FilterType.simpleDictionary = FilterType.simpleDictionary;
    const selectDictionaries = [
      'DEV_TYPE',
      'ADDRESS_OBJECT_LEVEL',
      'DEVICE_METER_TYPE',
      'TRANSFORMATION_RATIO_TYPE',
      'RES_TYPE',
      'MEASURE_PARAM_TYPE',
      'MEASURE_UNIT',
      'USD_DEV_TYPE',
      'MEASURE_OBJECT_CATEGORY',
      'BOILER_TYPE',
      'room_category',
      'FUEL_TYPE',
      'DEAERATOR_TYPE',
      'GAS_ANALYZER_TYPE',
      'DECARBONIZER_TYPE',
      'CONNECTION_TYPE',
      'REDUCER_LOAD_TYPE',
      'WATER_EQUIPMENT_TYPE',
      'FILTER_ELEMENT',
      'REAGENT_TYPE',
      'WELLHEAD_VALVE_TYPE',
      'WELLHEAD_VALVE_IMPL_TYPE',
      'VALVE_TYPE',
      'ELECTROMOTOR_TYPE',
      'ELECTROMOTOR_PURPOSE',
      'COMPRESSOR_TYPE',
      'MOTOR_TYPE',
      'HEAT_EXCHANGER_TYPE',
      'HEAT_EXCHANGER_IMPL_TYPE',
      'WELLHEAD_FITTING_MATERIAL',
      'FUEL_KIND',
      'PUMP_PURPOSE',
      'PUMP_TYPE',
      'RES_TYPE_SHORT',
      'BOILER_KIND',
      'ECONOMIZER_TYPE',
      'FAN_PURPOSE',
      'FAN_DIRECTION_TYPE',
      'ELECTROMOTOR_VOLTAGE',
      'FAN_DIRECTION',
      'DIESEL_FUEL_BRAND',
      'MANUFACTURE_MATERIAL',
      'GAS_ANALYZER_MEDIA',
      'GAS_PIPELINE_CATEGORY',
      'PIPELINE_GASKET_TYPE',
      'PIPELINE_ISOLATION_TYPE',
      'COMPENSATING_DEVICE_TYPE',
      'GAS_DISTRIBUTION_TYPE',
      'HEATING_KIND',
      'WATER_TANK_INSULATION_TYPE',
      'WATER_TANK_HEATING_TYPE',
      'SWITCH_TYPE',
      'SEASONS',
      'MEASURE_PARAM',
      'TMP_EEQ_STATE',
      'EVAPOR_PLANT_TYPE',
      'GATE_VALVE_PIPELINE',
      'GATE_VALVE_KIND',
      'VOLTAGE_CLASS',
      'UNIT_NAME',
      'HEAT_EXCHANGER_SCHEME',
      'TRANSFORMER_ACCURACY_CLASS',
      'TRANSFORMER_VOLTAGE_TYPE',
      'STORAGE_TANK_TYPE',
      'MANOMETER_TYPE',
      'THERMOMETER_TYPE',
      'SLEEVE_TYPE',
      'MEASURE_REPORT_CALC_ITEM_TYPE',
      'GRAPH_TYPE',
      'PLAN_TYPE',
      'LAYING_TYPE',
      'LAYING_METHOD',
      'ENGINEERING_NODE_TYPE',
      'COAL_GRADE',
      'BOILER_UNIT_TYPE',
      'INJECTOR_TYPE',
      'FUEL_BRAND',
      'OWN_NEEDS',
      'OWN_NEEDS_RATIO'
    ];
    if (selectDictionaries.includes(dictCode)) {
      type = FilterType.select;
    }
    return type;
  }

  public getSelectList(dictCode: string, params?: any): Observable<ISelectItem[]> {
    let result: Observable<ISelectItem[]> = of([]);
    switch (dictCode) {
      case 'FUEL_BRAND': {
        result = this.dictionaryApiService.getFuelBrandList();
        break;
      }
      case 'INJECTOR_TYPE': {
        result = this.dictionaryApiService.getInjectorTypeList();
        break;
      }
      case 'BOILER_UNIT_TYPE': {
        result = this.dictionaryApiService.getBoilerUnitTypeList();
        break;
      }
      case 'COAL_GRADE': {
        result = this.dictionaryApiService.getCoalGradeList();
        break;
      }
      case 'LAYING_TYPE': {
        result = this.dictionaryApiService.getLayingTypeList();
        break;
      }
      case 'LAYING_METHOD': {
        result = this.dictionaryApiService.getLayingMethodList();
        break;
      }
      case 'ENGINEERING_NODE_TYPE': {
        result = this.dictionaryApiService.getEngineeringNodeTypeList();
        break;
      }
      case 'DEV_TYPE': {
        result = this.dictionaryApiService.getDeviceTypeList(null, false);
        break;
      }
      case 'ADDRESS_OBJECT_LEVEL': {
        result = this.dictionaryApiService.getAddressObjectLevelList(true);
        break;
      }
      case 'DEVICE_METER_TYPE': {
        result = this.dictionaryApiService.getDeviceMeterType();
        break;
      }
      case 'TRANSFORMATION_RATIO_TYPE': {
        result = this.dictionaryApiService.getTransformationRatioType();
        break;
      }
      case 'RES_TYPE': {
        result = this.dictionaryApiService.getResourceTypeList([], false);
        break;
      }
      case 'RES_TYPE_SHORT': {
        result = this.dictionaryApiService.getResourceTypeList([], true);
        break;
      }
      case 'MEASURE_PARAM_TYPE': {
        result = this.dictionaryApiService.getMeasureParamTypeList();
        break;
      }
      case 'MEASURE_UNIT': {
        result = this.dictionaryApiService.getMeasureUnitList();
        break;
      }
      case 'USD_DEV_TYPE': {
        result = this.dictionaryApiService.getDeviceTypeList(['USPD', 'UPD'], true);
        break;
      }
      case 'MEASURE_OBJECT_CATEGORY': {
        result = this.dictionaryApiService.getMeasureObjectCategoryList();
        break;
      }
      case 'BOILER_TYPE': {
        result = this.dictionaryApiService.getBoilerTypeList();
        break;
      }
      case 'BOILER_KIND': {
        result = this.dictionaryApiService.getBoilerKindList();
        break;
      }
      case 'room_category': {
        result = this.dictionaryApiService.getRoomCategoryList();
        break;
      }
      case 'FUEL_TYPE': {
        result = this.dictionaryApiService.getFuelTypeList();
        break;
      }
      case 'FUEL_KIND': {
        result = this.dictionaryApiService.getFuelKindList();
        break;
      }
      case 'DEAERATOR_TYPE': {
        result = this.dictionaryApiService.getDeaeratorTypeList();
        break;
      }
      case 'GAS_ANALYZER_TYPE': {
        result = this.dictionaryApiService.getGasAnalyzerTypeList();
        break;
      }
      case 'DECARBONIZER_TYPE': {
        result = this.dictionaryApiService.getDecarbonizerTypeList();
        break;
      }
      case 'CONNECTION_TYPE': {
        result = this.dictionaryApiService.getConnectionTypeList();
        break;
      }
      case 'REDUCER_LOAD_TYPE': {
        result = this.dictionaryApiService.getReducerLoadTypeList();
        break;
      }
      case 'WATER_EQUIPMENT_TYPE': {
        result = this.dictionaryApiService.getWaterEquipmentTypeList();
        break;
      }
      case 'FILTER_ELEMENT': {
        result = this.dictionaryApiService.getFilterElementList();
        break;
      }
      case 'REAGENT_TYPE': {
        result = this.dictionaryApiService.getReagentTypeList();
        break;
      }
      case 'WELLHEAD_VALVE_TYPE': {
        result = this.dictionaryApiService.getWellheadValveTypeList();
        break;
      }
      case 'WELLHEAD_VALVE_IMPL_TYPE': {
        result = this.dictionaryApiService.getWellheadValveImplTypeList();
        break;
      }
      case 'PUMP_TYPE': {
        result = this.dictionaryApiService.getPumpTypeList();
        break;
      }
      case 'PUMP_PURPOSE': {
        result = this.dictionaryApiService.getPumpPurposeList();
        break;
      }
      case 'VALVE_TYPE': {
        result = this.dictionaryApiService.getValveTypeList();
        break;
      }
      case 'ELECTROMOTOR_TYPE': {
        result = this.dictionaryApiService.getMotorType();
        break;
      }
      case 'ELECTROMOTOR_PURPOSE': {
        result = this.dictionaryApiService.getMotorPurpose();
        break;
      }
      case 'COMPRESSOR_TYPE': {
        result = this.dictionaryApiService.getCompressorTypeList();
        break;
      }
      case 'MOTOR_TYPE': {
        result = this.dictionaryApiService.getMotorType();
        break;
      }
      case 'HEAT_EXCHANGER_TYPE': {
        result = this.dictionaryApiService.getHeatExchangerTypeList();
        break;
      }
      case 'HEAT_EXCHANGER_IMPL_TYPE': {
        result = this.dictionaryApiService.getHeatExchangerImplTypeList();
        break;
      }
      case 'WELLHEAD_FITTING_MATERIAL': {
        result = this.dictionaryApiService.getWellheadFittingMaterialList();
        break;
      }
      case 'FAN_DIRECTION_TYPE': {
        result = this.dictionaryApiService.getFanDirectionList();
        break;
      }
      case 'FAN_DIRECTION': {
        result = this.dictionaryApiService.getFanDirectionList();
        break;
      }
      case 'FAN_PURPOSE': {
        result = this.dictionaryApiService.getFanPurposeList();
        break;
      }
      case 'ECONOMIZER_TYPE': {
        result = this.dictionaryApiService.getEconomizerTypeList();
        break;
      }
      case 'ELECTROMOTOR_VOLTAGE': {
        result = this.dictionaryApiService.getMotorVoltage();
        break;
      }
      case 'DIESEL_FUEL_BRAND': {
        result = this.dictionaryApiService.getDieselFuelBrand();
        break;
      }
      case 'MANUFACTURE_MATERIAL': {
        result = this.dictionaryApiService.getManufactureMaterialList();
        break;
      }
      case 'GAS_ANALYZER_MEDIA': {
        result = this.dictionaryApiService.getGasAnalyzerMediaList();
        break;
      }
      case 'GAS_PIPELINE_CATEGORY': {
        result = this.dictionaryApiService.getGasPipelineCategoryList();
        break;
      }
      case 'PIPELINE_GASKET_TYPE': {
        result = this.dictionaryApiService.getPipingType();
        break;
      }
      case 'PIPELINE_ISOLATION_TYPE': {
        result = this.dictionaryApiService.getPipelineIsolationTypeList();
        break;
      }
      case 'COMPENSATING_DEVICE_TYPE': {
        result = this.dictionaryApiService.getCompensatingDevice();
        break;
      }
      case 'GAS_DISTRIBUTION_TYPE': {
        result = this.dictionaryApiService.getGasDistributionTypeList();
        break;
      }
      case 'HEATING_KIND': {
        result = this.dictionaryApiService.getHeatingKindList();
        break;
      }
      case 'WATER_TANK_INSULATION_TYPE': {
        result = this.dictionaryApiService.getWaterTankInsulationTypeList();
        break;
      }
      case 'WATER_TANK_HEATING_TYPE': {
        result = this.dictionaryApiService.getWaterTankHeatingTypeList();
        break;
      }
      case 'SWITCH_TYPE': {
        result = this.dictionaryApiService.getSwitchTypeList();
        break;
      }
      case 'SEASONS': {
        result = this.dictionaryApiService.getSeasons();
        break;
      }
      case 'MEASURE_PARAM': {
        const { measureParamTypeId, resourceTypeCode } = params;
        result = this.dictionaryApiService.getMeasureParamList(measureParamTypeId, resourceTypeCode);
        break;
      }
      case 'TMP_EEQ_STATE': {
        result = this.dictionaryApiService
          .getEngineerDeviceStateList()
          .pipe(map((items) => items.filter((item) => item.ext.code !== 'CREATED')));
        break;
      }
      case 'EVAPOR_PLANT_TYPE': {
        result = this.dictionaryApiService.getEvaporPlantTypeList();
        break;
      }
      case 'GATE_VALVE_PIPELINE': {
        result = this.dictionaryApiService.getGateValvePipelineList();
        break;
      }
      case 'GATE_VALVE_KIND': {
        result = this.dictionaryApiService.getGateValveKindList();
        break;
      }
      case 'VOLTAGE_CLASS': {
        result = this.dictionaryApiService.getVoltageClassList();
        break;
      }
      case 'UNIT_NAME': {
        result = this.dictionaryApiService.getUnitNameList();
        break;
      }
      case 'HEAT_EXCHANGER_SCHEME': {
        result = this.dictionaryApiService.getHeatExchangerSchemeList();
        break;
      }
      case 'TRANSFORMER_ACCURACY_CLASS': {
        result = this.dictionaryApiService.getTransformerAccuracyClassList();
        break;
      }
      case 'TRANSFORMER_VOLTAGE_TYPE': {
        result = this.dictionaryApiService.getTransformerVoltageTypeList();
        break;
      }
      case 'STORAGE_TANK_TYPE': {
        result = this.dictionaryApiService.getStorageTankTypeList();
        break;
      }
      case 'MANOMETER_TYPE': {
        result = this.dictionaryApiService.getManometerTypeList();
        break;
      }
      case 'THERMOMETER_TYPE': {
        result = this.dictionaryApiService.getThermometerTypeList();
        break;
      }
      case 'SLEEVE_TYPE': {
        result = this.dictionaryApiService.getSleeveTypeList();
        break;
      }
      case 'MEASURE_REPORT_CALC_ITEM_TYPE': {
        result = this.dictionaryApiService.getFormulasRegistryFormulaElementTypesList();
        break;
      }
      case 'GRAPH_TYPE': {
        result = this.dictionaryApiService.getGraphTypeList();
        break;
      }
      case 'PLAN_TYPE': {
        result = this.dictionaryApiService.getPlanTypeList();
        break;
      }
      case 'OWN_NEEDS': {
        result = this.dictionaryApiService.getOwnNeedsList();
        break;
      }
      case 'OWN_NEEDS_RATIO': {
        const { boilerTypeTd, fuelTypeId, ownNeedsId } = params;
        result = this.dictionaryApiService.getOwnNeedsRatioList(boilerTypeTd, fuelTypeId, ownNeedsId);
        break;
      }
    }
    return result.pipe(share());
  }

  public getDictionary(dictCode: string): IDictionaryField {
    let result: IDictionaryField = null;
    switch (dictCode) {
      case 'MEASURE_UNIT_RUSSIAN': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getMeasureUnitRussianDictionary(params),
          { onlyActual: false },
        );
        break;
      }
      case 'POWER_LINE_TYPE': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getPowerLineTypeDictionary(params),
          { onlyActual: false },
        );
        break;
      }
      case 'VOLTAGE_LEVEL': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getVoltageLevelDictionary(params),
          { onlyActual: false },
        );
        break;
      }
      case 'ISOLATION_MATERIAL': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getIsolationMaterialDictionary(params),
          { onlyActual: false },
        );
        break;
      }
      case 'OKI_OBJECT_KIND': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getOkiObjectKindDictionary(params),
          { onlyActual: false },
        );
        break;
      }
      case 'ENGINEERING_NODE_DIMENSION': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getEngineeringNodeDimensionDictionary(params),
          { onlyActual: false },
        );
        break;
      }
      case 'ENGINEERING_NODE_MATERIAL': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getEngineeringNodeMaterialDictionary(params),
          { onlyActual: false },
        );
        break;
      }
      case 'ENGINEERING_NODE_GOST': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getEngineeringNodeGostDictionary(params),
          { onlyActual: false },
        );
        break;
      }
      case 'ENGINEERING_NODE_USAGE_KIND': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getEngineeringNodeUsageKindDictionary(params),
          { onlyActual: false },
        );
        break;
      }
      case 'MEASURE_TOOL_NAME': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getMeasureToolsDictionary(params),
          { onlyActual: false, deviceTypeId: null },
        );
        break;
      }
      case 'MEASURE_TOOL_NAME_ACTUAL': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getMeasureToolsDictionary(params),
          { onlyActual: true, deviceTypeId: null },
        );
        break;
      }
      case 'MEASURE_TOOL_TYPE': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getMeasureToolTypeDictionary(params),
          { deviceTypeIds: null, measureToolId: null, onlyActual: false },
        );
        break;
      }
      case 'MEASURE_TOOL_TYPE_ACTUAL': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getMeasureToolTypeDictionary(params),
          { deviceTypeIds: null, measureToolId: null, onlyActual: true },
        );
        break;
      }
      case 'VENDOR': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getVendorsDictionary(params),
          { onlyActual: false },
        );
        break;
      }
      case 'ADDRESS_BUILDING_STATE': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getAddressBuildingStateDictionary(params),
          { onlyActual: false },
        );
        break;
      }
      case 'ADDRESS-BUILDING-STATE': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getAddressBuildingStateDictionary(params),
          { onlyActual: false },
        );
        break;
      }
      case 'ADDRESS_BUILDING_TYPE': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getAddressBuildingTypeDictionary(params),
          { onlyActual: false },
        );
        break;
      }
      case 'ADDRESS_OBJECT_TYPE': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getAddressObjectTypeDictionary(params),
          { onlyActual: false },
        );
        break;
      }
      case 'ADDRESS_ESTATE_TYPE': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getAddressEstateTypeDictionary(params),
          { onlyActual: false },
        );
        break;
      }
      case 'ADDRESS_MEASURE_OBJECT_TYPE': {
        result = this.dictionaryService.getDefaultDictionary((params) =>
          this.dictionaryApiService.getMeasureObjectTypeDictionary(params),
        );
        break;
      }
      case 'ORGANIZATION': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getOrganizationsDictionary(params),
          { onlyActual: false },
        );
        break;
      }
      case 'ORG': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getOrganizationsDictionary(params),
          { onlyActual: false },
        );
        break;
      }
      case 'ADDRESS': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getAddressDictionary(params),
          { onlyActual: false },
        );
        break;
      }
      case 'COMPOSITE_ADDRESS': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getCompositeAddressDictionary(params),
          { onlyActual: false },
        );
        break;
      }
      case 'BOILER_BRAND': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getBoilerBrandDictionary(params),
          { onlyActual: false },
        );
        break;
      }
      case 'MILITARY_TOWN': {
        result = this.dictionaryService.getDefaultDictionary((params) =>
          this.dictionaryApiService.getMilTownDictionary(params),
        );
        break;
      }
      case 'ID_DEVICE_BOILER': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getEngineeringDeviceIdDictionary(params),
          { engineeringDeviceTypeCode: EngineerDeviceType.BOILER },
        );
        break;
      }
      case 'ID_DEVICE_HEAT_EQ': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getEngineeringDeviceIdDictionary(params),
          { engineeringDeviceTypeCode: EngineerDeviceType.HEAT },
        );
        break;
      }
      case 'MEASURE_OBJECT': {
        result = this.dictionaryService.getDefaultDictionary((params) =>
          this.dictionaryApiService.getMeasureObjectIdDictionary(params),
          { onlyActual: false },
        );
        break;
      }
      case 'TMP_GEN_PLAN': {
        result = this.dictionaryService.getDefaultDictionary((params) =>
          this.dictionaryApiService.getMOGenplanNumberDictionary(params),
        );
        break;
      }
      case 'HEAT_EXCHANGER_BRAND': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getHeatExchangerBrandDictionary(params),
          { onlyActual: false },
        );
        break;
      }
      case 'ID_DEVICE_DECARBONIZER': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getEngineeringDeviceIdDictionary(params),
          { engineeringDeviceTypeCode: EngineerDeviceType.DECARBONIZER },
        );
        break;
      }
      case 'DECARBONIZER_BRAND': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getDecarbonizerBrandDictionary(params),
          { onlyActual: false },
        );
        break;
      }
      case 'ID_DEVICE_STEAM_LINE': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getEngineeringDeviceIdDictionary(params),
          { engineeringDeviceTypeCode: EngineerDeviceType.STEAM_LINE },
        );
        break;
      }
      case 'ID_DEVICE_PUMP': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getEngineeringDeviceIdDictionary(params),
          { engineeringDeviceTypeCode: EngineerDeviceType.PUMP },
        );
        break;
      }
      case 'ELECTROMOTOR_BRAND': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getElectromotorBrandDictionary(params),
          { onlyActual: false },
        );
        break;
      }
      case 'PUMP_BRAND': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getPumpBrandDictionary(params),
          { onlyActual: false },
        );
        break;
      }
      case 'ID_DEVICE_CHEMICAL_WATER_TREATMENT_EQ': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getEngineeringDeviceIdDictionary(params),
          {
            engineeringDeviceTypeCode: EngineerDeviceType.CHEMICAL_WATER_TREATMENT,
          },
        );
        break;
      }
      case 'CHEMICAL_TREATMENT_SYSTEM': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getChemicalTreatmentSystemBrandDictionary(params),
          { onlyActual: false },
        );
        break;
      }
      case 'ID_DEVICE_SMOKE_FAN': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getEngineeringDeviceIdDictionary(params),
          { engineeringDeviceTypeCode: EngineerDeviceType.SMOKE_FAN },
        );
        break;
      }
      case 'FAN_BRAND': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getFanBrandDictionary(params),
          { onlyActual: false },
        );
        break;
      }
      case 'ID_DEVICE_EE_GEN_RESERVE': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getEngineeringDeviceIdDictionary(params),
          { engineeringDeviceTypeCode: EngineerDeviceType.EE_GEN_RESERVE },
        );
        break;
      }
      case 'ID_DEVICE_EE_GEN_MAIN': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getEngineeringDeviceIdDictionary(params),
          { engineeringDeviceTypeCode: EngineerDeviceType.EE_GEN_MAIN },
        );
        break;
      }
      case 'DIESEL_GENERATOR_BRAND': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getDieselGeneratorBrandDictionary(params),
          { onlyActual: false },
        );
        break;
      }
      case 'ID_DEVICE_COMPRESSOR': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getEngineeringDeviceIdDictionary(params),
          { engineeringDeviceTypeCode: EngineerDeviceType.COMPRESSOR },
        );
        break;
      }
      case 'COMPRESSOR_BRAND': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getCompressorBrandDictionary(params),
          { onlyActual: false },
        );
        break;
      }
      case 'ID_DEVICE_CHIMNEY': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getEngineeringDeviceIdDictionary(params),
          { engineeringDeviceTypeCode: EngineerDeviceType.CHIMNEY },
        );
        break;
      }
      case 'CHIMNEY_BRAND': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getChimneyBrandDictionary(params),
          { onlyActual: false },
        );
        break;
      }
      case 'ID_DEVICE_GAS_ANALYZER': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getEngineeringDeviceIdDictionary(params),
          { engineeringDeviceTypeCode: EngineerDeviceType.GAS_ANALYZER },
        );
        break;
      }
      case 'GAS_ANALYZER_BRAND': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getGazAnalyzerBrandDictionary(params),
          { onlyActual: false },
        );
        break;
      }
      case 'ID_DEVICE_DEAERATOR': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getEngineeringDeviceIdDictionary(params),
          { engineeringDeviceTypeCode: EngineerDeviceType.DEAERATOR },
        );
        break;
      }
      case 'DEAERATOR_BRAND': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getDeaeratorBrandDictionary(params),
          { onlyActual: false },
        );
        break;
      }
      case 'ID_DEVICE_TRANSFORMER_SUBSTATION': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getEngineeringDeviceIdDictionary(params),
          {
            engineeringDeviceTypeCode: EngineerDeviceType.TRANSFORMER_SUBSTATION,
          },
        );
        break;
      }
      case 'ID_DEVICE_OUTDOOR_LIGHTING_CONTROL_LOCKER': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getEngineeringDeviceIdDictionary(params),
          {
            engineeringDeviceTypeCode: EngineerDeviceType.OUT_LIGHT_CONTROL_LOCKER,
          },
        );
        break;
      }
      case 'LIGHTING_CONTROL_BOX_BRAND': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getLightingControlBoxBrand(params),
          { onlyActual: false },
        );
        break;
      }
      case 'ID_DEVICE_GAS_PIPELINE': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getEngineeringDeviceIdDictionary(params),
          { engineeringDeviceTypeCode: EngineerDeviceType.GAS_PIPELINE },
        );
        break;
      }
      case 'ID_DEVICE_HEATING_NETWORK': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getEngineeringDeviceIdDictionary(params),
          { engineeringDeviceTypeCode: EngineerDeviceType.HEATING_NETWORK },
        );
        break;
      }
      case 'ID_DEVICE_GAS_DISTRIBUTION': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getEngineeringDeviceIdDictionary(params),
          { engineeringDeviceTypeCode: EngineerDeviceType.GAS_DISTRIBUTION },
        );
        break;
      }
      case 'REDUCER_BRAND': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getReducerBrandDictionary(params),
          { onlyActual: false },
        );
        break;
      }
      case 'ID_DEVICE_CONTROL_VALVES': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getEngineeringDeviceIdDictionary(params),
          { engineeringDeviceTypeCode: EngineerDeviceType.CONTROL_VALVES },
        );
        break;
      }
      case 'WELLHEAD_FITTING_BRAND': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getWellheadFittingBrandDictionary(params),
          { onlyActual: false },
        );
        break;
      }
      case 'ID_DEVICE_WATER_TOWER': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getEngineeringDeviceIdDictionary(params),
          { engineeringDeviceTypeCode: EngineerDeviceType.WATER_TOWER },
        );
        break;
      }
      case 'ID_DEVICE_ECONOMIZER': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getEngineeringDeviceIdDictionary(params),
          { engineeringDeviceTypeCode: EngineerDeviceType.ECONOMIZER },
        );
        break;
      }
      case 'ECONOMIZER_BRAND': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getEconomizerBrandDictionary(params),
          { onlyActual: false },
        );
        break;
      }
      case 'ID_DEVICE_WATER_STORAGE': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getEngineeringDeviceIdDictionary(params),
          { engineeringDeviceTypeCode: EngineerDeviceType.WATER_STORAGE },
        );
        break;
      }
      case 'ID_DEVICE_EVAPORATIVE_PLANT': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getEngineeringDeviceIdDictionary(params),
          { engineeringDeviceTypeCode: EngineerDeviceType.EVAPORATIVE_PLANT },
        );
        break;
      }
      case 'EVAPOR_PLANT_BRAND': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getEvaporPlantBrandDictionary(params),
          { onlyActual: false },
        );
        break;
      }
      case 'ID_DEVICE_SWITCH': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getEngineeringDeviceIdDictionary(params),
          { engineeringDeviceTypeCode: EngineerDeviceType.SWITCH },
        );
        break;
      }
      case 'ID_DEVICE_UNIT': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getEngineeringDeviceIdDictionary(params),
          { engineeringDeviceTypeCode: EngineerDeviceType.UNIT },
        );
        break;
      }
      case 'ID_DEVICE_SWITCHGEAR': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getEngineeringDeviceIdDictionary(params),
          { engineeringDeviceTypeCode: EngineerDeviceType.SWITCHGEAR },
        );
        break;
      }
      case 'ID_DEVICE_CELL_RU': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getEngineeringDeviceIdDictionary(params),
          { engineeringDeviceTypeCode: EngineerDeviceType.CELL_RU },
        );
        break;
      }
      case 'ID_DEVICE_TRANSFORMER_AMPERAGE': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getEngineeringDeviceIdDictionary(params),
          {
            engineeringDeviceTypeCode: EngineerDeviceType.TRANSFORMER_AMPERAGE,
          },
        );
        break;
      }
      case 'ID_DEVICE_TRANSFORMER_VOLTAGE': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getEngineeringDeviceIdDictionary(params),
          { engineeringDeviceTypeCode: EngineerDeviceType.TRANSFORMER_VOLTAGE },
        );
        break;
      }
      case 'ID_DEVICE_RZA': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getEngineeringDeviceIdDictionary(params),
          { engineeringDeviceTypeCode: EngineerDeviceType.DEVICE_RZA },
        );
        break;
      }
      case 'ID_DEVICE_PANEL': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getEngineeringDeviceIdDictionary(params),
          { engineeringDeviceTypeCode: EngineerDeviceType.PANEL },
        );
        break;
      }
      case 'ID_DEVICE_FILTER': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getEngineeringDeviceIdDictionary(params),
          { engineeringDeviceTypeCode: EngineerDeviceType.FILTER },
        );
        break;
      }
      case 'ID_DEVICE_VALVE': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getEngineeringDeviceIdDictionary(params),
          { engineeringDeviceTypeCode: EngineerDeviceType.VALVE },
        );
        break;
      }
      case 'VALVE_BRAND': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getValveBrandDictionary(params),
          { onlyActual: false },
        );
        break;
      }
      case 'ID_DEVICE_ELECTRIC_MOTOR': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getEngineeringDeviceIdDictionary(params),
          { engineeringDeviceTypeCode: EngineerDeviceType.ELECTRIC_MOTOR },
        );
        break;
      }
      case 'ID_DEVICE_PRESSURE_REDUCER': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getEngineeringDeviceIdDictionary(params),
          { engineeringDeviceTypeCode: EngineerDeviceType.PRESSURE_REDUCER },
        );
        break;
      }
      case 'CELL_RU_BRAND': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getCellRuBrandDictionary(params),
          { onlyActual: false },
        );
        break;
      }
      case 'SWITCH_BRAND': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getSwitchBrandDictionary(params),
          { onlyActual: false },
        );
        break;
      }
      case 'UNIT_BRAND': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getUnitBrandDictionary(params),
          { onlyActual: false },
        );
        break;
      }
      case 'TRANSFORMER_AMPERAGE_BRAND': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getTransformerAmperageBrandDictionary(params),
          { onlyActual: false },
        );
        break;
      }
      case 'TRANSFORMER_VOLTAGE_BRAND': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getTransformerVoltageBrandDictionary(params),
          { onlyActual: false },
        );
        break;
      }
      case 'RZA_BRAND': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getRzaBrandDictionary(params),
          { onlyActual: false },
        );
        break;
      }
      case 'PANEL_BRAND': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getPanelBrandDictionary(params),
          { onlyActual: false },
        );
        break;
      }
      case 'FILTER_BRAND': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getFilterBrandDictionary(params),
          { onlyActual: false },
        );
        break;
      }
      case 'TRANSFORMATION_RATIO': {
        result = this.dictionaryService.getDefaultDictionary((params) =>
          this.dictionaryApiService.getTransformationRatio(params),
        );
        break;
      }
      case 'ID_DEVICE_STORAGE_TANK': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getEngineeringDeviceIdDictionary(params),
          { engineeringDeviceTypeCode: EngineerDeviceType.STORAGE_TANK },
        );
        break;
      }
      case 'ID_DEVICE_MANOMETER': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getEngineeringDeviceIdDictionary(params),
          { engineeringDeviceTypeCode: EngineerDeviceType.MANOMETER },
        );
        break;
      }
      case 'MANOMETER_BRAND': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getManometerBrandDictionary(params),
          { onlyActual: false },
        );
        break;
      }
      case 'ID_DEVICE_THERMOMETER': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getEngineeringDeviceIdDictionary(params),
          { engineeringDeviceTypeCode: EngineerDeviceType.THERMOMETER },
        );
        break;
      }
      case 'THERMOMETER_BRAND': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getThermometerBrandDictionary(params),
          { onlyActual: false },
        );
        break;
      }
      case 'ID_DEVICE_SLEEVE': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getEngineeringDeviceIdDictionary(params),
          { engineeringDeviceTypeCode: EngineerDeviceType.SLEEVE },
        );
        break;
      }
      case 'GAS_DISTRIB_BRAND': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getGasDistribBrandDictionary(params),
          { onlyActual: false },
        );
        break;
      }
      case 'TRANSFORMER_SUBST_BRAND': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getTransformerSubstBrandDictionary(params),
          { onlyActual: false },
        );
        break;
      }
      case 'STEAM_LINE_BRAND': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getSteamLineBrandDictionary(params),
          { onlyActual: false },
        );
        break;
      }
      case 'GAS_PIPELINE_BRAND': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getGasPipelineBrandDictionary(params),
          { onlyActual: false },
        );
        break;
      }
      case 'HEATING_NETWORK_BRAND': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getHeatingNetworkBrandDictionary(params),
          { onlyActual: false },
        );
        break;
      }
      case 'WATER_STORAGE_BRAND': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getWaterStorageBrandDictionary(params),
          { onlyActual: false },
        );
        break;
      }
      case 'SWITCHGEAR_BRAND': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getSwitchgearBrandDictionary(params),
          { onlyActual: false },
        );
        break;
      }
      case 'STORAGE_TANK_BRAND': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getStorageTankBrandDictionary(params),
          { onlyActual: false },
        );
        break;
      }
      case 'SLEEVE_BRAND': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getSleeveBrandDictionary(params),
          { onlyActual: false },
        );
        break;
      }
      case 'WATER_TOWER_BRAND': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getWaterTowerBrandDictionary(params),
          { onlyActual: false },
        );
        break;
      }
      case 'ORGANIZATION_TREE': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getOrganizationTreeDictionary(params),
          { onlyActual: false },
        );
        break;
      }
      case 'ORGANIZATION_TREE_TYPE': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getOrganizationTreeTypeDictionary(params),
          { onlyActual: false },
        );
        break;
      }
      case 'ORGANIZATION_DEPARTMENT': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getOrganizationDepartmentDictionary(params),
          { onlyActual: false },
        );
        break;
      }
      case 'ORGANIZATION_KIND': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getOrganizationKindDictionary(params),
          { onlyActual: false },
        );
        break;
      }
      case 'INCIDENT_CATEGORY': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getIncidentCategoryDictionary(params),
          { onlyActual: false },
        );
        break;
      }
      case 'REGION': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getRegionDictionary(params),
          {},
        );
        break;
      }
      case 'PLACE': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getRegionPlaceDictionary(params),
          {},
        );
        break;
      }
      case 'REGION_RF': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getRFRegion(params),
          {},
        );
        break;
      }
      case 'FEDERAL_DISTRICT': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getFederalDistrict(params),
          {},
        );
        break;
      }
      case 'MILITARY_DISTRICT': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getMilitaryDistrict(params),
          {},
        );
        break;
      }
      case 'TROOP_TYPE': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getTroopType(params),
          {},
        );
        break;
      }
    }
    return result;
  }

  public getEmptyList(): ISelectItem[] {
    return this.emptyList;
  }

  public getActiveList2(): ISelectItem[] {
    return this.activeList2;
  }
}
