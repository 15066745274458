<div class="AddressTreeClass">
    <div class="address-tree-header">
        {{ 'ADDRESS_CARD.TREE_TITLE' | translate}}
    </div>
    <div class="address-tree-body scroll-block">
        <div class="address-element-in-tree"
             *ngFor="let item of tree; let i = index">
            <div class="element-body"
                 [class.oe-selected]="i == selectedIndex">
                <button class="element-text"
                        (click)="selectElement(i)">
                    <div class="oe-header">
                        {{firstLatterToUpperCase(item.objectTypeFullName)}}
                    </div>
                    <div class="oe-name">
                        {{item.name}}
                    </div>
                </button>
                <lib-button *ngIf="canRemoveElementFromTree(item)"
                            class="element-delete"
                            [buttonStyles]="['icon']"
                            icon="icon-clearer"
                            iconSize="20"
                            (OnClick)="removeElement(i)">
                </lib-button>
            </div>
            <button *ngIf="canInsertElement(i); else borderBlock"
                    class="insert-element-container"
                    (click)="insertElement(i)">
                <div class="insert-element-line"></div>
                <div class="insert-element-plus-container">
                    <span class="font-icons icon-plus"></span>
                </div>
            </button>
            <ng-template #borderBlock>
                <div class="element-border-container">
                    <div class="element-border"></div>
                </div>
            </ng-template>
        </div>

        <div class="address-element-in-tree"
             *ngIf="object">
            <div class="element-body"
                 [class.oe-selected]="selectedIndex == 'object'">
                <button class="element-text"
                        (click)="selectObject()">
                    <div class="oe-header">
                        {{firstLatterToUpperCase(object.objectTypeFullName)}}
                    </div>
                    <div class="oe-name">
                        {{object.name}}
                    </div>
                </button>
                <lib-button *ngIf="regime === 'add'"
                            class="element-delete"
                            [buttonStyles]="['icon']"
                            icon="icon-clearer"
                            iconSize="20"
                            (OnClick)="removeObject()">
                </lib-button>
            </div>
        </div>

        <div class="address-tree-add-oe"
             *ngIf="canAddElementOrObject()">
            <div class="oe-header">
                {{'GENERAL.ADD' | translate}}
            </div>
            <lib-button class="add-oe-button black element"
                        [title]="'ADDRESS_CARD.ADD_ELEMENT' | translate"
                        (OnClick)="addElement()">
            </lib-button>
            <lib-button *ngIf="canAddObject()"
                        class="add-oe-button black"
                        [title]="'ADDRESS_CARD.ADD_OBJECT' | translate"
                        (OnClick)="addObject()">
            </lib-button>
        </div>
    </div>
</div>