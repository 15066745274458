import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  delay,
  map,
  retryWhen,
  switchMap,
  tap,
} from 'rxjs/operators';
import { forkJoin, Observable, of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ILogFileInfo } from '@app/core/interfaces/log-file-info';
import { ProfileDownloadsJournalApiService } from "@core/services/api/personal-area/profile-downloads-journal-api.service";
import { TranslateService } from '@ngx-translate/core';
import { IDownloadsJournalProgressResponse } from '@app/core/interfaces/downloads-journal';

@Component({
  selector: 'app-process-progress-indicator-modal',
  templateUrl: './process-progress-indicator-modal.component.html',
  styleUrls: ['./process-progress-indicator-modal.component.scss'],
})
export class ProcessProgressIndicatorModalComponent
  <
    WithFiles extends boolean = true,
    ReturnType extends unknown = WithFiles extends true ? ILogFileInfo[] : null
  >
  implements OnInit {
  /**
   * UUID процесса
   */
  @Input() set uuid(value: string | string[]) {
    this.uuids = Array.isArray(value) ? value : [value];
  }
  /**
   * Заголовок окна
   */
  @Input() title: string;
  /**
   * Шаблон заголовка окна
   */
  @Input() titleTmpl: TemplateRef<any>;
  /**
   * Контекст шаблона заголовка окна
   */
  @Input() titleTmplData: any = null;
  /**
   * Текст выполнения процесса
   */
  @Input() processMessage: string;
  /**
   * Шаблон выполнения процесса
   */
  @Input() processMessageTmpl: TemplateRef<any>;
  /**
   * Контекст шаблона выполнения процесса
   */
  @Input() processMessageTmplData: any = null;
  /**
   * Текст завершения процесса
   */
  @Input() successMessage: string;
  /**
   * Шаблон завершения процесса
   */
  @Input() successMessageTmpl: TemplateRef<any>;
  /**
   * Контекст шаблона завершения процесса
   */
  @Input() successMessageTmplData: any = null;
  /**
   * Сообщение для тоста после завершения процесса
   */
  @Input() closedIndicatorMessage: string;
  /**
   * URL для кнопки перехода
   * (журнал выгрузок, событий и тп)
   */
  @Input() goToButtonUrl: string = null;
  /**
   * Тайтл кнопки перехода
   * @default 'GENERAL.GO_TO_DOWNLOADS_JOURNAL'
   */
  @Input() goToButtonTitle: string = this.translateService.instant('GENERAL.GO_TO_DOWNLOADS_JOURNAL');
  /**
   * Коллбек кнопки перехода
   */
  @Input() goToButtonCallback: () => void = () => { };
  /**
   * Тайтл кнопки закрытия
   * @default 'GENERAL.CLOSE'
   */
  @Input() closeButtonTitle: String = this.translateService.instant('GENERAL.CLOSE');
  /**
   * Коллбек кнопки закрытия
   */
  @Input() closeButtonCallback: () => void = () => { };
  /**
   * Получение информации о загруженных файлах после завершения процесса
   * @default true
   */
  @Input() getFilesInfoOnCompletion: WithFiles = <WithFiles>true;
  /**
   * Коллбек завершения
   */
  @Input() onSuccess: (data: ReturnType) => void = () => { };
  /**
   * Коллбек ошибки
   */
  @Input() onError: (error: any) => void = () => { };

  public isSuccess = false;
  public isOpen = true;
  public processResults: IDownloadsJournalProgressResponse[];
  public fileInfoList: ILogFileInfo[];

  public uuids: string[];

  constructor(
    private translateService: TranslateService,
    private activeModalService: NgbActiveModal,
    private profileDownloadsJournalApiService: ProfileDownloadsJournalApiService,
    private toastrService: ToastrService,
  ) { }

  ngOnInit(): void {
    const requestList = this.getProcessRequestList(this.uuids);
    forkJoin(requestList)
      .pipe(
        tap(res => {
          this.processResults = res;
        }),
        switchMap(() => this.getFilesInfoOnCompletion
          ? this.profileDownloadsJournalApiService.getLogFilesInfo(this.uuids)
          : of(null)
        )
      )
      .subscribe(
        (data: ReturnType) => {
          this.isSuccess = true;
          if (Array.isArray(data)) {
            this.fileInfoList = data.filter(
              (item) => item.fileName && item.reportLogId,
            );
          }
          if (!this.isOpen && this.closedIndicatorMessage) {
            this.toastrService.success(this.closedIndicatorMessage);
          }
          this.onSuccess(data);
        },
        error => {
          this.onError(error);
          this.activeModalService.close();
        }
      );
  }

  public goTo(): void {
    this.goToButtonCallback();
    if (this.goToButtonUrl) {
      window.open(this.goToButtonUrl, '_blank');
    }
  }

  public close(): void {
    this.activeModalService.close();
    this.isOpen = false;
    this.closeButtonCallback();
  }

  private getProcessRequestList(uuids: string[]): Observable<IDownloadsJournalProgressResponse>[] {
    return uuids.map((uuid) =>
      this.profileDownloadsJournalApiService.getProgress(uuid).pipe(
        map((progress) => {
          if (progress?.isCompleted) {
            return progress;
          }
          throw progress;
        }),
        retryWhen((error) => error.pipe(delay(5000))),
      ),
    );
  }
}
