<div class="NetworkSectionOperationHistoryCardClose">
  <lib-button [buttonStyles]="['icon']"
              icon="icon-calendar_clear"
              iconSize="21"
              (OnClick)="close()">
  </lib-button>
</div>
<lib-loader [show]="isLoading$ | async"></lib-loader>
<div class="NetworkSectionOperationHistoryCardClass">
  <div class="modal-header">
    <div class="network-section-operation-history-card-title">
      {{'ENERGY_SYSTEMS.NETWORKS.SECTION.CARD.HISTORY.OPERATION_HISTORY.CARD.TITLE' | translate}}
    </div>
  </div>
  <div class="modal-body">
    <div class="network-section-operation-history-card-block scroll-block">
      <lib-accordion-item class="network-section-operation-history-card-block-item card-section"
                          [item]="accordionItems[0]"
                          (setOpen)="toggleAccordionItem($event)">
        <div class="network-section-operation-history-card-block-item-content">
          <div class="flex-container default-item-width-25">
            <ng-container *ngIf="!!info">
              <div class="flex-container-item">
                <div class="flex-container-item-label required">
                  {{ 'ENERGY_SYSTEMS.NETWORKS.SECTION.CARD.HISTORY.OPERATION_HISTORY.CARD.FIELDS.ID' | translate }}
                </div>
                <div class="flex-container-item-value">
                  {{info.id}}
                </div>
              </div>
              <div class="flex-container-item">
                <div class="flex-container-item-label required">
                  {{ 'ENERGY_SYSTEMS.NETWORKS.SECTION.CARD.HISTORY.OPERATION_HISTORY.CARD.FIELDS.SECTION_ID' | translate
                  }}
                </div>
                <div class="flex-container-item-value">
                  <span>{{info.networkSectionId}}</span>
                  <ng-container *ngIf="info && info.networkSectionId">
                    <lib-button class="flex-container-item-value-link"
                                [buttonStyles]="['icon']"
                                icon="icon-link"
                                iconSize="18"
                                (OnClick)="goToNetworkSection()">
                    </lib-button>
                  </ng-container>
                </div>
              </div>
              <div class="flex-container-item">
                <div class="flex-container-item-label required">
                  {{ 'ENERGY_SYSTEMS.NETWORKS.SECTION.CARD.HISTORY.OPERATION_HISTORY.CARD.FIELDS.START_DATE' | translate
                  }}
                </div>
                <div class="flex-container-item-value">
                  {{info.startDate | formatDate : 'ONLY_DATE' : dateFormat}}
                </div>
              </div>
              <div class="flex-container-item">
                <div class="flex-container-item-label">
                  {{ 'ENERGY_SYSTEMS.NETWORKS.SECTION.CARD.HISTORY.OPERATION_HISTORY.CARD.FIELDS.END_DATE' | translate
                  }}
                </div>
                <div class="flex-container-item-value">
                  {{info.endDate | formatDate : 'ONLY_DATE' : dateFormat}}
                </div>
              </div>

              <div class="flex-container-item">
                <div class="flex-container-item-label required">
                  {{ 'ENERGY_SYSTEMS.NETWORKS.SECTION.CARD.HISTORY.OPERATION_HISTORY.CARD.FIELDS.CREATED_DATE' |
                  translate }}
                </div>
                <div class="flex-container-item-value">
                  {{info.created | formatDate : 'JSON' : dateTimeFormat}}
                </div>
              </div>
              <div class="flex-container-item">
                <div class="flex-container-item-label">
                  {{ 'ENERGY_SYSTEMS.NETWORKS.SECTION.CARD.HISTORY.OPERATION_HISTORY.CARD.FIELDS.DELETED_DATE' |
                  translate }}
                </div>
                <div class="flex-container-item-value">
                  {{info.deleted | formatDate : 'JSON' : dateTimeFormat}}
                </div>
              </div>
              <div class="flex-container-item">
                <div class="flex-container-item-label required">
                  {{ 'ENERGY_SYSTEMS.NETWORKS.SECTION.CARD.HISTORY.OPERATION_HISTORY.CARD.FIELDS.SYSTEM_CODE' |
                  translate }}
                </div>
                <div class="flex-container-item-value">
                  {{info.systemCode}}
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </lib-accordion-item>
      <lib-accordion-item class="network-section-operation-history-card-block-item card-section"
                          [item]="accordionItems[1]"
                          (setOpen)="toggleAccordionItem($event)">
        <div class="network-section-operation-history-card-block-item-content">
          <div class="flex-container default-item-width-25">
            <ng-container *ngIf="!!info">
              <div class="flex-container-item">
                <div class="flex-container-item-label required">
                  {{ 'ENERGY_SYSTEMS.NETWORKS.SECTION.CARD.HISTORY.OPERATION_HISTORY.CARD.FIELDS.OBJECT_ID' | translate
                  }}
                </div>
                <div class="flex-container-item-value">
                  <span>{{info.inId}}</span>
                  <ng-container *ngIf="info && info.inId">
                    <lib-button class="flex-container-item-value-link"
                                [buttonStyles]="['icon']"
                                icon="icon-link"
                                iconSize="18"
                                (OnClick)="goToMeasureObject(info.inId)">
                    </lib-button>
                  </ng-container>
                </div>
              </div>
              <div class="flex-container-item">
                <div class="flex-container-item-label">
                  {{ 'ENERGY_SYSTEMS.NETWORKS.SECTION.CARD.HISTORY.OPERATION_HISTORY.CARD.FIELDS.OBJECT_CATEGORY' |
                  translate }}
                </div>
                <div class="flex-container-item-value">
                  {{info.inCategoryName}}
                </div>
              </div>
              <div class="flex-container-item">
                <div class="flex-container-item-label">
                  {{ 'ENERGY_SYSTEMS.NETWORKS.SECTION.CARD.HISTORY.OPERATION_HISTORY.CARD.FIELDS.OBJECT_TYPE' |
                  translate }}
                </div>
                <div class="flex-container-item-value">
                  {{info.inType}}
                </div>
              </div>
              <div class="flex-container-item">
                <div class="flex-container-item-label">
                  {{ 'ENERGY_SYSTEMS.NETWORKS.SECTION.CARD.HISTORY.OPERATION_HISTORY.CARD.FIELDS.OBJECT_NAME' |
                  translate }}
                </div>
                <div class="flex-container-item-value">
                  {{info.inName}}
                </div>
              </div>

              <div class="flex-container-item width-50">
                <div class="flex-container-item-label">
                  {{ 'ENERGY_SYSTEMS.NETWORKS.SECTION.CARD.HISTORY.OPERATION_HISTORY.CARD.FIELDS.OBJECT_ADDRESS' |
                  translate }}
                </div>
                <div class="flex-container-item-value">
                  {{info.inAddress}}
                </div>
              </div>
              <div *ngIf="info.inCategoryCode && info.inCategoryCode !== MeasureObjectCategory.ENG_NODE"
                   class="flex-container-item">
                <div class="flex-container-item-label required">
                  {{ 'ENERGY_SYSTEMS.NETWORKS.SECTION.CARD.HISTORY.OPERATION_HISTORY.CARD.FIELDS.OBJECT_ATTACH_LOAD' |
                  translate }}
                </div>
                <div class="flex-container-item-value">
                  {{info.inAttachedLoad}}
                </div>
              </div>
              <div *ngIf="info.inCategoryCode && info.inCategoryCode !== MeasureObjectCategory.ENG_NODE
              && networkResourceType && networkResourceType === ResourceType.HEAT_ENERGY"
                   class="flex-container-item">
                <div class="flex-container-item-label">
                  {{
                  'ENERGY_SYSTEMS.NETWORKS.SECTION.CARD.HISTORY.OPERATION_HISTORY.CARD.FIELDS.OBJECT_ATTACH_LOAD_HEATING'
                    | translate }}
                </div>
                <div class="flex-container-item-value">
                  {{info.inAttachedLoadHeating}}
                </div>
              </div>
              <div *ngIf="info.inCategoryCode && info.inCategoryCode !== MeasureObjectCategory.ENG_NODE
              && networkResourceType && networkResourceType === ResourceType.HEAT_ENERGY"
                   class="flex-container-item">
                <div class="flex-container-item-label">
                  {{
                  'ENERGY_SYSTEMS.NETWORKS.SECTION.CARD.HISTORY.OPERATION_HISTORY.CARD.FIELDS.OBJECT_ATTACH_HOT_WATER' |
                    translate }}
                </div>
                <div class="flex-container-item-value">
                  {{info.inAttachedLoadHotWater}}
                </div>
              </div>
              <div *ngIf="info.inCategoryCode && info.inCategoryCode !== MeasureObjectCategory.ENG_NODE
              && networkResourceType && networkResourceType === ResourceType.HEAT_ENERGY"
                   class="flex-container-item">
                <div class="flex-container-item-label">
                  {{
                  'ENERGY_SYSTEMS.NETWORKS.SECTION.CARD.HISTORY.OPERATION_HISTORY.CARD.FIELDS.OBJECT_ATTACH_LOAD_VENT' |
                    translate }}
                </div>
                <div class="flex-container-item-value">
                  {{info.inAttachedLoadVent}}
                </div>
              </div>
              <div *ngIf="info.inCategoryCode && info.inCategoryCode !== MeasureObjectCategory.ENG_NODE
              && networkResourceType && networkResourceType === ResourceType.HEAT_ENERGY"
                   class="flex-container-item">
                <div class="flex-container-item-label">
                  {{
                  'ENERGY_SYSTEMS.NETWORKS.SECTION.CARD.HISTORY.OPERATION_HISTORY.CARD.FIELDS.OBJECT_ATTACH_LOAD_VAPOR'
                    | translate }}
                </div>
                <div class="flex-container-item-value">
                  {{info.inAttachedLoadVapor}}
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </lib-accordion-item>
      <lib-accordion-item class="network-section-operation-history-card-block-item card-section"
                          [item]="accordionItems[2]"
                          (setOpen)="toggleAccordionItem($event)">
        <div class="network-section-operation-history-card-block-item-content">
          <div class="flex-container default-item-width-25">
            <ng-container *ngIf="!!info">
              <div class="flex-container-item">
                <div class="flex-container-item-label required">
                  {{ 'ENERGY_SYSTEMS.NETWORKS.SECTION.CARD.HISTORY.OPERATION_HISTORY.CARD.FIELDS.OBJECT_ID' | translate
                  }}
                </div>
                <div class="flex-container-item-value">
                  <span>{{info.outId}}</span>
                  <ng-container *ngIf="info && info.outId">
                    <lib-button class="flex-container-item-value-link"
                                [buttonStyles]="['icon']"
                                icon="icon-link"
                                iconSize="18"
                                (OnClick)="goToMeasureObject(info.outId)">
                    </lib-button>
                  </ng-container>
                </div>
              </div>
              <div class="flex-container-item">
                <div class="flex-container-item-label">
                  {{ 'ENERGY_SYSTEMS.NETWORKS.SECTION.CARD.HISTORY.OPERATION_HISTORY.CARD.FIELDS.OBJECT_CATEGORY' |
                  translate }}
                </div>
                <div class="flex-container-item-value">
                  {{info.outCategoryName}}
                </div>
              </div>
              <div class="flex-container-item">
                <div class="flex-container-item-label">
                  {{ 'ENERGY_SYSTEMS.NETWORKS.SECTION.CARD.HISTORY.OPERATION_HISTORY.CARD.FIELDS.OBJECT_TYPE' |
                  translate }}
                </div>
                <div class="flex-container-item-value">
                  {{info.outType}}
                </div>
              </div>
              <div class="flex-container-item">
                <div class="flex-container-item-label">
                  {{ 'ENERGY_SYSTEMS.NETWORKS.SECTION.CARD.HISTORY.OPERATION_HISTORY.CARD.FIELDS.OBJECT_NAME' |
                  translate }}
                </div>
                <div class="flex-container-item-value">
                  {{info.outName}}
                </div>
              </div>

              <div class="flex-container-item width-50">
                <div class="flex-container-item-label">
                  {{ 'ENERGY_SYSTEMS.NETWORKS.SECTION.CARD.HISTORY.OPERATION_HISTORY.CARD.FIELDS.OBJECT_ADDRESS' |
                  translate }}
                </div>
                <div class="flex-container-item-value">
                  {{info.outAddress}}
                </div>
              </div>
              <div *ngIf="info.outCategoryCode && info.outCategoryCode !== MeasureObjectCategory.ENG_NODE"
                   class="flex-container-item">
                <div class="flex-container-item-label required">
                  {{ 'ENERGY_SYSTEMS.NETWORKS.SECTION.CARD.HISTORY.OPERATION_HISTORY.CARD.FIELDS.OBJECT_ATTACH_LOAD' |
                  translate }}
                </div>
                <div class="flex-container-item-value">
                  {{info.outAttachedLoad}}
                </div>
              </div>
              <div *ngIf="info.outCategoryCode && info.outCategoryCode !== MeasureObjectCategory.ENG_NODE
              && networkResourceType && networkResourceType === ResourceType.HEAT_ENERGY"
                   class="flex-container-item">
                <div class="flex-container-item-label">
                  {{
                  'ENERGY_SYSTEMS.NETWORKS.SECTION.CARD.HISTORY.OPERATION_HISTORY.CARD.FIELDS.OBJECT_ATTACH_LOAD_HEATING'
                    | translate }}
                </div>
                <div class="flex-container-item-value">
                  {{info.outAttachedLoadHeating}}
                </div>
              </div>
              <div *ngIf="info.outCategoryCode && info.outCategoryCode !== MeasureObjectCategory.ENG_NODE
              && networkResourceType && networkResourceType === ResourceType.HEAT_ENERGY"
                   class="flex-container-item">
                <div class="flex-container-item-label">
                  {{
                  'ENERGY_SYSTEMS.NETWORKS.SECTION.CARD.HISTORY.OPERATION_HISTORY.CARD.FIELDS.OBJECT_ATTACH_HOT_WATER' |
                    translate }}
                </div>
                <div class="flex-container-item-value">
                  {{info.outAttachedLoadHotWater}}
                </div>
              </div>
              <div *ngIf="info.outCategoryCode && info.outCategoryCode !== MeasureObjectCategory.ENG_NODE
              && networkResourceType && networkResourceType === ResourceType.HEAT_ENERGY"
                   class="flex-container-item">
                <div class="flex-container-item-label">
                  {{
                  'ENERGY_SYSTEMS.NETWORKS.SECTION.CARD.HISTORY.OPERATION_HISTORY.CARD.FIELDS.OBJECT_ATTACH_LOAD_VENT' |
                    translate }}
                </div>
                <div class="flex-container-item-value">
                  {{info.outAttachedLoadVent}}
                </div>
              </div>
              <div *ngIf="info.outCategoryCode && info.outCategoryCode !== MeasureObjectCategory.ENG_NODE
              && networkResourceType && networkResourceType === ResourceType.HEAT_ENERGY"
                   class="flex-container-item">
                <div class="flex-container-item-label">
                  {{
                  'ENERGY_SYSTEMS.NETWORKS.SECTION.CARD.HISTORY.OPERATION_HISTORY.CARD.FIELDS.OBJECT_ATTACH_LOAD_VAPOR'
                    | translate }}
                </div>
                <div class="flex-container-item-value">
                  {{info.outAttachedLoadVapor}}
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </lib-accordion-item>
    </div>
  </div>
</div>
