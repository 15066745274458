<div class="ExpireDateTextComponentClass">
  <span>{{valueText ? (valueText | date: 'dd.MM.yyyy') : ''}}</span>
  <span *ngIf="isVisibleIcon"
        class="font-icons {{iconClass}}"
        [ngbTooltip]="expireDateTooltip"
        (mouseenter)="hoverIcon(true)"
        (mouseleave)="hoverIcon(false)"
        container="body">
    <span class="path1"></span>
    <span class="path2"></span>
    <span class="path3"></span>
  </span>
</div>

<ng-template #expireDateTooltip>
  <div class="expire-date-text__tooltip">
    <ng-container *ngIf="expiredDays >= 0; else daysOfExpiry">
      <span>
        {{textOfType}}
        {{valueText ? (valueText | date: 'dd.MM.yyyy') : ''}}
      </span>
      <span>
        {{"EXPIRY_DATE_TOOLTIP.DAYS_OVERDUE" | translate}}
        {{expiredDays | numberAbs}}
      </span>
    </ng-container>
    <ng-template #daysOfExpiry>
      <span>
        {{textOfType}}
        {{valueText ? (valueText | date: 'dd.MM.yyyy') : ''}}
      </span>
      <span>
        {{"EXPIRY_DATE_TOOLTIP.DAYS_LEFT" | translate}}
        {{expiredDays | numberAbs}}
      </span>
    </ng-template>
  </div>
</ng-template>
