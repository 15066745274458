<div class="AddressSearchClass">
    <input #AddressSearchField
           class="address-search-field"
           [class.filled]="displayItems && displayItems.length > 0"
           [placeholder]="'ADDRESS_CARD.SEARCH_PLACEHOLDER' | translate"
           [(ngModel)]="value"
           type="text">

    <ng-container *ngIf="!loading else loadingIconTemp">
        <lib-button *ngIf="!!value else searchIconTemp"
                    class="address-search-icon"
                    [buttonStyles]="['icon']"
                    icon="icon-calendar_clear"
                    iconSize="17"
                    (OnClick)="clear()">
        </lib-button>
        <ng-template #searchIconTemp>
            <div class="address-search-icon">
                <span class="font-icons icon-search"></span>
            </div>
        </ng-template>
    </ng-container>
    <ng-template #loadingIconTemp>
        <div class="address-search-icon refresh-animation">
            <span class="font-icons icon-refresh"></span>
        </div>
    </ng-template>

    <ng-container *ngIf="!!displayItems?.length">
        <div class="address-search-filled-border"></div>
        <div class="address-search-list scroll-block">
            <div *ngFor="let item of displayItems"
                 class="address-search-list-item"
                 (click)="select(item)"
                 [class.preselected]="item === preselectedValue">
                {{item.name}}
            </div>
        </div>
    </ng-container>
</div>