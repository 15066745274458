import { finalize, map } from 'rxjs/operators';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { SystemParamsService } from "@core/services/system-params.service";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import { GuideApiService } from "@core/services/api/admin/guide-api.service";
import { IFileInfo } from 'web-frontend-component-library/interfaces';
import { UtilsService } from 'web-frontend-component-library/services';
import { SYSTEM_PARAMETER_NAME } from 'web-frontend-component-library/enums';

@Component({
  selector: 'app-add-guide-modal',
  templateUrl: './add-guide-modal.component.html',
  styleUrls: ['./add-guide-modal.component.scss']
})
export class AddGuideModalComponent implements OnInit, OnDestroy {

  @ViewChild('uploadInput') uploadInput: ElementRef;

  public file: IFileInfo = null;

  private successLoad: string = '';
  private errorLoad: string = '';

  public isLoading: boolean = false;
  public maxFileSize: number = 50;
  private systemParams$: Subscription = new Subscription();

  constructor(
    public translateService: TranslateService,
    public activeModal: NgbActiveModal,
    private systemParamsService: SystemParamsService,
    private guideApiService: GuideApiService,
    private toastr: ToastrService,
    private utilsService: UtilsService
  ) {
    this.translateService.get(['GUIDE']).subscribe(res => {
      this.successLoad = res['GUIDE']['SUCCESS_LOAD_GUIDE'];
      this.errorLoad = res['GUIDE']['ERROR_LOAD_GUIDE'];
    });
  }

  ngOnInit() {
    this.systemParams$ = this.systemParamsService.getSystemParams()
      .subscribe((res: any) => {
        this.maxFileSize = res && res[SYSTEM_PARAMETER_NAME.FILE_SIZE]
          ? Number(res[SYSTEM_PARAMETER_NAME.FILE_SIZE])
          : 50;
      });
  }

  ngOnDestroy() {
    this.systemParams$.unsubscribe();
  }

  public cancel(): void {
    this.activeModal.close();
  }

  public apply(): void {
    if (!this.file) {
      return;
    }
    this.isLoading = true;
    this.guideApiService.addGuideFile(this.file.fileObject)
      .pipe(map(this.utilsService.getIdToFile()), finalize(() => { this.isLoading = false; }))
      .subscribe((fileId: number) => {
        if (fileId) {
          this.isLoading = true;
          this.guideApiService.addGuide(fileId).subscribe(
            () => {
              this.toastr.success(this.successLoad);
              this.activeModal.dismiss();
            },
            error => {
              this.isLoading = false;
              this.toastr.error(
                error?.error?.message ?? '',
                this.errorLoad
              );
            });
        }
      }, (error) => {
        this.isLoading = false;
        this.toastr.error(
          error?.error?.message ?? '',
          this.errorLoad
        );
      });
  }

}
