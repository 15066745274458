import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "@env/environment";
import { share } from "rxjs/operators";
import { FileService } from "web-frontend-component-library/services";

/**
 * АПИ руководства пользователя.
 */
@Injectable({
  providedIn: 'root'
})
export class GuideApiService {

  constructor(
    private HttpClient: HttpClient,
    private fileService: FileService
  ) {
  }

  /**
   * Добавить файл руководства пользователя
   * @param file файл
   */
  public addGuideFile(file: File): Observable<any> {
    return this.fileService.uploadFile(`${environment.API_URL}/admin/user/manual/upload`, file);
  }

  /**
   * Скачать руководство пользователя
   */
  public getGuideFile() {
    this.fileService.downloadFile(
      `${environment.API_URL}/admin/user/manual/download/stream`,
      null, {}, false);
  }

  /**
   * Загрузить руководство пользователя
   */
  public addGuide(fileId: number): Observable<number> {
    return this.HttpClient
      .post<number>(`${environment.API_URL}/admin/user/manual`, { fileId: fileId })
      .pipe(share());
  }
}
