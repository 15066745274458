import { AddressApiService } from '@core/services/api/address/address-api.service';
import { IAddressObjectRich, IAddressElementRich, ICreateAddressObject, ICreateAddressElement, ICreateAddressTreeObject, ICreateAddressTreeElement } from '@core/interfaces/address';
import { DateTimeService } from 'web-frontend-component-library/services';
import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ISelectItem } from 'web-frontend-component-library/interfaces';

@Injectable({
  providedIn: 'root'
})
export class AddressCardService {

  private dateFormat: string = this.dateTimeService.getDateFormat('date');

  constructor(
    private dateTimeService: DateTimeService,
    private addressApiService: AddressApiService
  ) {
  }

  /**
   * Подготовить даты объекта для вывода в шаблон
   * @param item подробные данные адресного элемента или объекта
   */
  public prepareDatesForTemplate(item: IAddressObjectRich | IAddressElementRich): IAddressObjectRich | IAddressElementRich {
    return {
      ...item,
      startDate: item.startDate ? this.dateTimeService.getDateFromBackend(item.startDate, this.dateFormat) : null,
      endDate: item.endDate ? this.dateTimeService.getDateFromBackend(item.endDate, this.dateFormat) : null,
      created: item.created ? this.dateTimeService.getDateFromBackend(item.created, this.dateFormat) : null,
      modified: item.modified ? this.dateTimeService.getDateFromBackend(item.modified, this.dateFormat) : null,
      deleted: item.deleted ? this.dateTimeService.getDateFromBackend(item.deleted, this.dateFormat) : null,
      stateDate: item.stateDate ? this.dateTimeService.getDateFromBackend(item.stateDate, this.dateFormat) : null
    };
  }

  /**
   * Подготовить даты объекта перед отправкой в бэк
   * @param item данные адресного элемента или объекта (подробные или из дерева)
   */
  public prepareDatesForBackend(item: IAddressObjectRich | IAddressElementRich | ICreateAddressObject | ICreateAddressElement)
    : IAddressObjectRich | IAddressElementRich | ICreateAddressObject | ICreateAddressElement {
    if (this.instanceOfRich(item)) {
      return {
        ...item,
        startDate: item.startDate ? this.dateTimeService.getBackendDateFromString(item.startDate, this.dateFormat) : null,
        endDate: item.endDate ? this.dateTimeService.getBackendDateFromString(item.endDate, this.dateFormat) : null,
        created: item.created ? this.dateTimeService.getBackendDateFromString(item.created, this.dateFormat) : null,
        modified: item.modified ? this.dateTimeService.getBackendDateFromString(item.modified, this.dateFormat) : null,
        deleted: item.deleted ? this.dateTimeService.getBackendDateFromString(item.deleted, this.dateFormat) : null,
        stateDate: item.stateDate ? this.dateTimeService.getBackendDateFromString(item.stateDate, this.dateFormat) : null
      };
    } else {
      return {
        ...item,
        startDate: item.startDate ? this.dateTimeService.getBackendDateFromString(item.startDate, this.dateFormat) : null,
        endDate: item.endDate ? this.dateTimeService.getBackendDateFromString(item.endDate, this.dateFormat) : null
      }
    }
  }

  /**
   * Проверка типа объекта
   * @param item
   */
  private instanceOfRich(item: IAddressObjectRich | IAddressElementRich | ICreateAddressObject | ICreateAddressElement)
    : item is IAddressElementRich | IAddressObjectRich {
    return (item as any).created !== undefined ? true : false;
  }



  /**
   * Получить подробные данные об элементе из бэка
   * @param el данные элемента из дерева
   */
  public getElementRichData(el: ICreateAddressTreeElement): Observable<IAddressElementRich> {
    let param = el.localId ? el.localId : el.addressRegistryId;
    let req = el.localId ? this.addressApiService.getElementLocalInfo(param) : this.addressApiService.getElementGlobalInfo(param);
    return req.pipe(
      map(res => <IAddressElementRich>this.prepareDatesForTemplate(res))
    );
  }

  /**
   * Получить подробные данные об объекте из бэка.
   * @param obj данные объекта из дерева
   */
  public getObjectRichData(obj: ICreateAddressTreeObject): Observable<IAddressObjectRich> {
    let param = obj.localId ? obj.localId : obj.addressRegistryId;
    let req = obj.localId ? this.addressApiService.getObjectLocalInfo(param) : this.addressApiService.getObjectGlobalInfo(param);
    return req.pipe(
      map(res => <IAddressObjectRich>this.prepareDatesForTemplate(res))
    );
  }

  /**
   * Получить данные для редактирования объекта в режима edit из подробных данных элемента
   * @param obj подробные данные объекта
   */
  public mapObjectRichDataForEditing(obj: IAddressObjectRich): ICreateAddressObject {
    return {
      addInfo: obj.addInfo,
      addressBuildingStateId: obj.addressBuildingStateId,
      addressBuildingTypeId: obj.addressBuildingTypeId,
      addressEstateTypeId: obj.addressEstateTypeId,
      addressTreeId: obj.addressTreeId,
      buildingNumber: obj.buildingNumber,
      endDate: obj.endDate,
      housingNumber: obj.housingNumber,
      id: obj.id,
      kadastrNumber: obj.kadastrNumber,
      objectNumber: obj.objectNumber,
      postalCode: obj.postalCode,
      startDate: obj.startDate
    }
  }

  /**
   * Получить данные для редактирования элемента в режиме edit из подробных данных элемента
   * @param el подробные данные элемента
   */
  public mapElementRichDataForEditing(el: IAddressElementRich): ICreateAddressElement {
    return {
      endDate: el.endDate,
      id: el.id,
      isActual: el.isActual,
      liveStatus: el.liveStatus,
      name: el.name ? el.name : el.fullName,
      objectTypeFullName: el.objectTypeFullName,
      objectTypeShortName: el.objectTypeShortName,
      parentId: el.parentId,
      postalCode: el.postalCode,
      startDate: el.startDate
    }
  }

  /**
   * Получить вид владений, вид строения и состояние строения.
   * 1 - building state (состояние строения),
   * 2 - building type (вид строения),
   * 3 - estate type (вид владения).
   */
  public getObjectTypes(obj: ICreateAddressTreeObject): [ISelectItem, ISelectItem, ISelectItem] {
    // в режиме add вручную выбранные типы
    if (obj.addressEstateType || obj.addressBuildingState || obj.addressBuildingType) {
      return [
        obj.addressBuildingState ? { ...obj.addressBuildingState } : null,
        obj.addressBuildingType ? { ...obj.addressBuildingType } : null,
        obj.addressEstateType ? { ...obj.addressEstateType } : null,
      ];
    }
    // в режиме edit как правило можно маппить, т.к. необходимые поля есть в подробных данных
    else if (obj.richData) {
      return this.mapObjectTypesData(obj.richData);
    }
    else {
      return [null, null, null];
    }
  }

  /**
   * Получить данные для справочников из подробных данных объекта
   * 1 - building state,
   * 2 - building type,
   * 3 - estate type.
   * @param obj объект
   */
  private mapObjectTypesData(obj: IAddressObjectRich): [ISelectItem, ISelectItem, ISelectItem] {
    let res: [ISelectItem, ISelectItem, ISelectItem] = [null, null, null];
    let keys = [
      'addressBuildingState',
      'addressBuildingType',
      'addressEstateType'
    ]
    keys.forEach((key, i) => {
      if (obj[key + 'Id'] && obj[key + 'Name']) {
        res[i] = {
          key: obj[key + 'Id'],
          value: obj[key + 'Name']
        }
        if (obj[key + 'Code']) {
          res[i].ext = {
            code: obj[key + 'Code']
          }
        }
      }
    });
    return res;
  }

  /**
   * Получить полное название адреса
   * на основе дерева элементов и объекта
   */
  public getAddressTitle(tree: ICreateAddressTreeElement[], obj: ICreateAddressTreeObject): string {
    let parts: string[] = [];
    if (tree) {
      parts = tree.map(item => `${item.objectTypeFullName} ${item.name}`);
    }
    if (obj) {
      let types = this.getObjectTypes(obj);
      // владение
      if (types[2] && types[2].value && (obj.addressManual || obj.richData)) {
        parts.push(`${types[2].value.toLowerCase()} ${obj.addressManual
          ? obj.addressManual.objectNumber
          : obj.richData.objectNumber
          }`);
      }
      // корпус
      parts.push(
        obj.addressManual
          ? obj.addressManual.housingNumber
            ? `корпус ${obj.addressManual.housingNumber}`
            : null
          : obj.richData && obj.richData.housingNumber
            ? `корпус ${obj.richData.housingNumber}`
            : null
      );
      // строение
      if (types[1] && types[1].value && (obj.addressManual || obj.richData)) {
        parts.push(`${types[1].value.toLowerCase()} ${obj.addressManual
          ? obj.addressManual.buildingNumber
          : obj.richData.buildingNumber
          }`);
      }

      var objAddInfo = obj.addressManual
        ? obj.addressManual.addInfo
        : obj.richData && obj.richData.addInfo
          ? obj.richData.addInfo
          : null;
    }
    let res = parts.filter(item => item != null).join(', ');
    if (objAddInfo && objAddInfo != '') {
      res += `. ${objAddInfo}`;
    }
    return res;
  }
}
