import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { IAdminGroupAccess } from '@core/interfaces/admin-groups';
import { DictionaryApiService } from '@core/services/api/dictionary-api.service';
import { Subscription } from 'rxjs';
import { ISelectItem } from 'web-frontend-component-library/interfaces';
import { UtilsService } from 'web-frontend-component-library/services';

@Component({
  selector: 'app-group-type-settings',
  templateUrl: './group-type-settings.component.html',
  styleUrls: ['./group-type-settings.component.scss'],
})
export class GroupTypeSettingsComponent implements OnInit, OnDestroy {
  @Input() set groupAccess(value: IAdminGroupAccess) {
    this.accTypeAccessList =
      value && value.accTypeAccessList ? value.accTypeAccessList : [];
    this.mpTypeAccessList =
      value && value.mpTypeAccessList ? value.mpTypeAccessList : [];
    this.resTypeAccessList =
      value && value.resTypeAccessList ? value.resTypeAccessList : [];
  }
  @Input() readonly = true; // режим чтения
  @Output() OnChange: EventEmitter<IAdminGroupAccess> =
    new EventEmitter<IAdminGroupAccess>(); // изменение доступов

  public accTypeAccessList: number[] = [];
  public mpTypeAccessList: number[] = [];
  public resTypeAccessList: number[] = [];

  public resTypes: ISelectItem[] = [];
  public accountTypes: ISelectItem[] = [];
  public measurePointTypes: ISelectItem[] = [];

  private subscriptions: Subscription[] = [];

  constructor(
    private dictionaryApiService: DictionaryApiService,
    private utilsService: UtilsService,
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.dictionaryApiService.getResourceTypeList([], true).subscribe(
        (list: ISelectItem[]) => {
          this.resTypes =
            list && list.length > 0 ? this.prepareResTypes(list) : [];
        },
        (error) => {
          this.resTypes = [];
        },
      ),
    );
    this.subscriptions.push(
      this.dictionaryApiService.getAccountingTypesList().subscribe(
        (list: ISelectItem[]) => {
          this.accountTypes = list;
        },
        (error) => {
          this.accountTypes = [];
        },
      ),
    );
    this.subscriptions.push(
      this.dictionaryApiService.getMeasurePointTypesList().subscribe(
        (list: ISelectItem[]) => {
          this.measurePointTypes = list;
        },
        (error) => {
          this.measurePointTypes = [];
        },
      ),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  private prepareResTypes(list: ISelectItem[]): ISelectItem[] {
    return list.map((item: ISelectItem) => {
      item.iconClass =
        item.ext && item.ext.code
          ? this.utilsService.getResourceTypeIcon(item.ext.code)
          : null;
      return item;
    });
  }

  public changeTypeResource(value: Array<string | number>) {
    const result =
      value && value.length > 0
        ? value.map((v: string | number) => Number(v))
        : [];
    if (result.length > 0) {
      result.sort(function (a, b) {
        return a - b;
      });
    } else {
      this.mpTypeAccessList = [];
      this.accTypeAccessList = [];
    }
    this.resTypeAccessList = result;
    this.OnChange.emit({
      accTypeAccessList: this.accTypeAccessList,
      mpTypeAccessList: this.mpTypeAccessList,
      resTypeAccessList: this.resTypeAccessList,
    });
  }

  public changeTypeAccount(value: Array<string | number>) {
    const result =
      value && value.length > 0
        ? value.map((v: string | number) => Number(v))
        : [];
    if (result.length > 0) {
      result.sort(function (a, b) {
        return a - b;
      });
    }
    this.accTypeAccessList = result;
    this.OnChange.emit({
      accTypeAccessList: this.accTypeAccessList,
      mpTypeAccessList: this.mpTypeAccessList,
      resTypeAccessList: this.resTypeAccessList,
    });
  }

  public changeTypeMeasurePoint(value: Array<string | number>) {
    const result =
      value && value.length > 0
        ? value.map((v: string | number) => Number(v))
        : [];
    if (result.length > 0) {
      result.sort(function (a, b) {
        return a - b;
      });
    }
    this.mpTypeAccessList = result;
    this.OnChange.emit({
      accTypeAccessList: this.accTypeAccessList,
      mpTypeAccessList: this.mpTypeAccessList,
      resTypeAccessList: this.resTypeAccessList,
    });
  }
}
